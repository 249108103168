import './DropdownMenu.css'

const colorDict={
    'Pending':'rgb(22,180,255)',
    'Testing':'orange',
    'Resolved':'rgb(47,255,208)',
}
export default function Component(props){
    const select=(item,index)=>()=>{
        if(props.select)props.select(item,index)
    }
    return(
        <div className='relative dropdown_main select-none cursor-pointer text-black'>
            <div className="hover:bg-[#888] flex flex-row items-center text-[12px] gap-[8px] px-[16px] py-[4px] rounded-[16px] border pointer-cursor justify-between" style={{width:props.isFullWidth?'100%':'auto',minWidth:props.minWidth||180,border:'.5px solid black',color:'black'}}> 
                {props.value}
                <DownArrow/>
            </div>
            <div className='absolute top-[100%] left-0 dropdown_main_expand flex-col text-xs bg-white w-full gap-[4px] rounded-[4px] border border-[#aaa] max-h-[200px]' style={{overflowY:'auto', zIndex:999}}>
                {(props.options).map((item,index)=>
                    <div key={index} onClick={select(item,index)} className='px-[16px] py-[4px] hover:text-white hover:bg-[#00000040] cursor-pointer' style={{color:'black'}}> {item}</div>
                )}
            </div>
        </div>
    )
}

const DownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={7}
    fill="none"
    {...props}
  >
    <path stroke="#000" d="m1 1.243 4.242 4.242 4.243-4.242" />
  </svg>
)
