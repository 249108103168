import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../svgs/TgatherLogoSvg';
import { useState } from "react";

export default function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo=(str)=>()=>{
        navigate(str)
    }
    const [isShowTimeSchedule,setisShowTimeSchedule]=useState(false)


    return(
        <div className="w-full h-full flex flex-col items-center justify-start bg-[#ddd] overflow-hidden">
            
            <div className="w-full h-full flex-col flex" style={{overflow:'auto'}}>
                <img src='/a4.png' className="w-full d-flex-m-hidden" style={{}}/>
                <img src='/a4_.png' className="w-full d-hidden-m-flex" style={{}}/>
                <div className="w-full flex-1 flex flex-col items-center d-pad-x-64-m-8">
                    <div className="max-w-[1420px] w-full flex flex-col justify-start items-center ">
                        
                        <div className="w-full flex flex-col justify-start items-center p-[24px]" style={{background: 'linear-gradient(180deg, rgba(230, 235, 237, 0) 0%, #FFFFFF 72%)'}}>
                            
                            <div className="text-[#6E604C] text-[32px] font-[700] mt-[24px] d-flex-m-hidden">第四屆香港國際⼈才⾼峰論壇</div>
                            <div className="text-[#6E604C] text-[22px] font-[700] mt-[24px] d-hidden-m-flex">第四屆香港國際⼈才⾼峰論壇</div>
                            <div className="text-[#6E604C] text-[24px] font-[350] d-flex-m-hidden">⼈才引領，創新驅動，賦能新質⽣產⼒</div>
                            <div className="text-[#6E604C] text-[16px] font-[350] d-hidden-m-flex">⼈才引領，創新驅動，賦能新質⽣產⼒</div>

                            <div className="max-w-[1000px] w-full text-[#6E604C] text-[13px] font-[400] mt-[24px] d-text-center-m-text-left">
                                為積極落實二十屆三中全會精神,配合國家人才工作戰略目標以及粵港澳大灣區發展規劃,全力支持特區政府施政,香港國際人才高峰論壇組委會擬攜手社會各界共同舉辦「第四屆香港國際人才高峰論壇」。
                                {/* <br/> */}
                                {/* 「第四屆香港國際人才高峰論壇」以「人才引領,創新驅動,賦能新質生產力」為主題,匯聚海內外政要、專家、學者和企業家及各領域高端人才,共同探討國際人才競爭、國家人才發展、和區域人才聯動等重要課題,同場亦將舉辦海南和南沙招才引智及其與香港聯合引才簽約活動,充分發揮香港「背靠祖國,聯通世界」的獨特優勢,助力海南自貿港和粵港澳大灣區建設,促進海內外人才的跨界交流和合作,積極踐行國家人才戰略,為國家高質量發 展提供人才和智力支持。 */}
                            </div>

                            <div className="max-w-[1000px] w-full p-[24px] rounded-[24px] flex flex-col items-center justify-center mt-[24px]" style={{backdropFilter: 'blur(100px)',border: '2px solid #FFFFFF',background: 'linear-gradient(180deg, rgba(230, 235, 237, 0.3) 28.5%, #FFFFFF 100%)'}}>
                                <div  className="text-[#6E604C] text-[20px] font-[350]">峰會詳情</div>
                                <div className="flex flex-row flex-wrap w-full justify-center items-center text-[#473F3A] font-500 d-text-24-m-14 mt-[24px]">
                                     {/* <img src='/a3.png' className="w-full max-w-[715px]" style={{}}/> */}
                                    <div className="flex flex-row items-center gap-[4px] w-full d-center-m-start my-[4p] d-text-24-m-12">
                                        <img src='/date.png' alt='date' className="w-[22px]"/>
                                        2024 年 12 月 13 日（星期五 下午）
                                    </div>
                                    {/* <div className="d-flex-m-hidden flex-row items-center gap-[4px] d-w-50-m-100 flex-row d-center-m-start my-[4px]">
                                        <img src='/time.png' alt='time'/>
                                        13：00 展覽 14：30 論壇 18：00閉幕
                                        <div className="cursor-pointer" onClick={()=>{setisShowTimeSchedule(true)}}>
                                            <img className='w-[24px] h-[24px]' alt='info' src='/infoicon.png'/>
                                        </div>
                                    </div> */}
                                    <div className="flex flex-row items-center gap-[4px] w-full d-center-m-start my-[4px] d-text-24-m-12">
                                        <img src='/time.png' alt='time' className="w-[22px]"/>
                                        13：00 展覽 &nbsp;14：30 論壇 &nbsp;18：00閉幕
                                        {/* <div className="cursor-pointer" onClick={()=>{setisShowTimeSchedule(true)}}>
                                            <img className='w-[24px] h-[24px]' alt='info' src='/infoicon.png'/>
                                        </div> */}
                                    </div>
                                    {/* <div className="d-hidden-m-flex flex-row items-center gap-[4px] d-w-50-m-100 flex-row d-center-m-start my-[4px]">
                                        <img src='/time.png' alt='time'/>
                                        14：30 論壇
                                        <div className="cursor-pointer" onClick={()=>{setisShowTimeSchedule(true)}}>
                                            <img className='w-[24px] h-[24px]' alt='info' src='/infoicon.png'/>
                                        </div>
                                    </div>
                                    <div className="d-hidden-m-flex flex-row items-center gap-[4px] d-w-50-m-100 flex-row d-center-m-start my-[4px]">
                                        <img src='/time.png' alt='time'/>
                                        18：30 晚宴
                                        <div className="cursor-pointer" onClick={()=>{setisShowTimeSchedule(true)}}>
                                            <img className='w-[24px] h-[24px]' alt='info' src='/infoicon.png'/>
                                        </div>
                                    </div> */}
                                    <div className="flex flex-row items-center gap-[4px] w-full flex flex-row d-center-m-start my-[4px] d-text-24-m-12">
                                        <img src='/location.png' alt='location' className="w-[22px]"/>
                                        香港 港島海逸君綽酒店 香港北角油街23號 (港鐵炮台山站A出口）
                                    </div>
                                </div>
                                <div onClick={navigateTo('/register')} className="max-w-full register-button mt-[32px] cursor-pointer flex justify-center items-center text-white d-text-24-m-14 font-[700] rounded-[99px]" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                                    按此登記參加
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex flex-col justify-start items-center p-[24px] pt-[0]" style={{background: 'white'}}>
                            {/* <img src='/a1.jpg' className="max-w-[1000px] w-full"/>
                            <img src='/a2.jpg' className="max-w-[1000px] w-full"/>
                            */}

                            {/* <div className="max-w-[1000px] w-full flex flex-row flex-wrap justify-between mb-[24px]" style={{}}>
                                <img src='/info1.png' className="d-w-32-m-100 my-[16px]" style={{height:undefined,aspectRatio:341/311}} alt='info'/>
                                <img src='/info2.png' className="d-w-32-m-100 my-[16px]" style={{height:undefined,aspectRatio:341/311}} alt='info'/>
                                <img src='/info3.png' className="d-w-32-m-100 my-[16px]" style={{height:undefined,aspectRatio:341/311}} alt='info'/>

                            </div> */}
                        </div>
                
                    </div>
                </div>
                {/*footer */}
                <div className="flex flex-row w-full justify-between items-center px-[24px] py-[16px] footer-font-1 text-[#6E604C] font-[700] bg-[#ddd] gap-[24px]">
                    港才薈 TGather
                    <div className="footer-font-2">
                        © 2024 Tgather Limited. All right reserved.
                    </div>
                </div>
                
            </div>

            {isShowTimeSchedule&&<div className="absolute top-0 bottom-0 left-0 right-0 bg-[#00000090] max-h-[100%] z-[100] flex justify-center items-center">
                <div className="absolute top-0 bottom-0 left-0 right-0 z-[101]" onClick={()=>{setisShowTimeSchedule(false)}}/>
                <div className="max-w-[96%] max-h-[90%] w-[90%] p-[24px] bg-white rounded-[24px] flex flex-col jusitfy-start items-center z-[102]" style={{overflowY:'auto'}}>
                    <div className="font-[700] text-[#6E604C] text-[24px]">論壇流程</div>
                    <div className="flex flex-row w-[100%] mt-[24px] font-[700] text-[#6E604C] py-[4px] " style={{borderBottom:'2px solid #6E604C'}}>
                        <div className="flex-1 text-center">時間</div>
                        <div className="flex-[2] text-center">論壇流程</div>
                    </div>
                    <div className="flex flex-row w-[100%] mt-[24px] font-[300] text-[#000] py-[4px] items-center text-[12px]">
                        <div className="flex-1 text-center">14:00-14:30 </div>
                        <div className="flex-[2] text-center">恭候及就座</div>
                    </div>
                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px] bg-[#00000020]">
                        <div className="flex-1 text-center">14:30-14:35</div>
                        <div className="flex-[2] text-center">活動開始，司儀介紹主禮嘉賓</div>
                    </div>
                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px]">
                        <div className="flex-1 text-center">14:35-14:36 </div>
                        <div className="flex-[2] text-center">奏唱國歌</div>
                    </div>
                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px] bg-[#00000020]">
                        <div className="flex-1 text-center">14:36-14:40</div>
                        <div className="flex-[2] text-center">組委會主席致歡迎辭</div>
                    </div>

                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px]">
                        <div className="flex-1 text-center">14:40-15:00</div>
                        <div className="flex-[2] text-center">特區政府領導、內地政府領導致開幕詞</div>
                    </div>
                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px] bg-[#00000020]">
                        <div className="flex-1 text-center">15:00-15:10</div>
                        <div className="flex-[2] text-center font-[700]">主禮嘉賓上台啓動儀式、為參與（內地）政府和機構、演講嘉賓致送感謝狀並大合影</div>
                    </div>

                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px]">
                        <div className="flex-1 text-center">15:10-16:00</div>
                        <div className="flex-[2] text-center">
                            <b className="font-[700]">主題一（50分鐘）</b><br/>
                            <b  className="font-[700]">生成式AI引領科技革命，未來國際人才如何應對挑戰？</b><br/>
                            主題演講<br/>
                            圓桌論壇
                        </div>
                    </div>
                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px] bg-[#00000020]">
                        <div className="flex-1 text-center">16:00-17:10</div>
                        <div className="flex-[2] text-center">
                            <b className="font-[700]">主题二（70分钟）</b><br/>
                            <b  className="font-[700]">發揮海南自貿港開放優勢，推動中國特色創新發展</b><br/>
                            主題演講<br/>
                            海南省相關政府、國企和香港及灣區的人才機構簽約儀式<br/>
                            圓桌論壇
                        </div>
                    </div>

                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px]">
                        <div className="flex-1 text-center">17:10-18:00</div>
                        <div className="flex-[2] text-center">
                            <b className="font-[700]">主題三（50分鐘）</b><br/>
                            <b  className="font-[700]">共建大灣區人才高地，賦能新質生產力發展</b><br/>
                            主題演講<br/>
                            粵港澳人才協作辦公室香港工作站揭牌儀式<br/>
                            圓桌論壇
                        </div>
                    </div>
                    <div className="flex flex-row w-[100%] font-[300] text-[#000] py-[4px] items-center text-[12px] bg-[#00000020]">
                        <div className="flex-1 text-center">18:30-21:00</div>
                        <div className="flex-[2] text-center font-[700]">
                            閉幕晚宴
                        </div>
                    </div>

                    <div onClick={()=>{setisShowTimeSchedule(false)}} className="w-[140px] h-[39px] cursor-pointer flex justify-center items-center text-white text-[14px] font-[500] rounded-[99px] mt-[16px]" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                        返回
                    </div>
                    
                </div>
            </div>}
            
        </div>
    )
}