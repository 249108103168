import './App.css';
import { Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import {COOKIESPREFIX} from './config.js';
import { Login, setuserData } from './actions/actions'
import NavBar from './components/NavBar';
import React, {useRef} from 'react'
//pages
import PageNotFound from './screens/PageNotFound'
import Home from './screens/Home';
import AboutUs from './screens/AboutUs';
import Register from './screens/Register';
import EventDetails from './screens/EventDetails';
import Privacy from './screens/Privacy';
import EventApproval from './screens/EventApproval.js';
import DeleteAccountDetails from './screens/DeleteAccountDetails.js';
import MembershipApproval from './screens/MembershipApproval.js';

import { getProfile, validToken } from './services/users.js';


function App(props) {
  const [cookies, setCookie, removeCookie] = useCookies([`${COOKIESPREFIX}_cred`, `${COOKIESPREFIX}_token`]);

  useEffect(() => {
    // console.log(props)
    async function check_token() {
      let login_cred = cookies[`${COOKIESPREFIX}_cred`];
      let token = cookies[`${COOKIESPREFIX}_token`];
      if (token && login_cred) {
        let res = await validToken(token)
        if (res.errno === 0 && login_cred.email && login_cred.usertype) {
          props.dispatch(Login(true, token, login_cred.usertype, login_cred.email))
          updateUserData(token)
        }
        //props.dispatch(Login(true, token, login_cred.usertype, login_cred.username))
      } else {
        try{
            removeCookie(`${COOKIESPREFIX}_token`, '/')
        }catch(e){
            console.log(e)
        }
      }
    }
    check_token()
  }, [])

  const updateUserData=async (token)=>{
    let result = await getProfile(token)
    if(result?.result){
        props.dispatch(setuserData(result.result))
    }
}

  return (
    <div className="flex full-viewport-height flex flex-col" style={{fontFamily: 'Oxygen',letterSpacing:1.05}}>

      <NavBar />
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/index.html" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/register" element={<Register />} />
        <Route path='/event' element={<EventDetails/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
        <Route path='/google-delete-account-info' element={<DeleteAccountDetails/>}/>
        <Route path='/privacy.html' element={<Privacy/>}/>
        <Route path='/admin/event' element={<EventApproval/>}/>
        <Route path='/admin/membership' element={<MembershipApproval/>}/>

        
        
        {/* <Route path="/login" element={<LoginPage />} />
        <Route path="/purchase/:id" element={<Purchase />} />
        <Route path="/records" element={<Record />} /> */}
        
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default connect((store) => {
  return {
    token: store.tokenReducer,
    control: store.controlReducer,
  }
})(App)

