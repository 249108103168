import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginWithEmailOtp, requestOtpWithEmail, uploadNewUserProfile } from "../services/users";
import LoadingPage from "../components/Loading";
import DownArrow from '../svgs/DownArrow';
import CompleteSvg from '../svgs/CompleteSvg';
import { participateEvent,checkIfEmailRegisteredEvent } from "../services/events";
import {POSITIONS} from '../utils/positions';

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export default function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo=(str)=>()=>{
        navigate(str)
    }
    const [isloading,setisloading]=useState(false);
    const ref1=useRef(null)
    const ref2=useRef(null)
    const ref3=useRef(null)
    const ref4=useRef(null)
    const ref5=useRef(null)
    const ref6=useRef(null)
    const [num1,setnum1]=useState('')
    const [num2,setnum2]=useState('')
    const [num3,setnum3]=useState('')
    const [num4,setnum4]=useState('')
    const [num5,setnum5]=useState('')
    const [num6,setnum6]=useState('')
    const [countdown,setcountdown]=useState(0)

    const [isVerified,setisVerified]=useState(false)
    const [isShowOtpPopup,setisShowOtpPopup]=useState(false)
    const [data,setData]=useState({})
    const onChangeEmail=(e)=>{setData({...data,email:e.target.value?.toLowerCase()})}
    const onChangeMobile=(e)=>{setData({...data,mobile:e.target.value})}
    const onChangefirstname=(e)=>{setData({...data,firstname:e.target.value})}
    const onChangelastname=(e)=>{setData({...data,lastname:e.target.value})}
    const onChangeenglishName=(e)=>{setData({...data,englishName:e.target.value})}
    const onChangecompany=(e)=>{setData({...data,company:e.target.value})}
    const onChangeeposition=(e)=>{setData({...data,position:e.target.value})}
    const onChangeWechat=(e)=>{setData({...data,wechat:e.target.value})}


    const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
        useEffect(()=>{
            function handleWindowResize() {setWindowSize(getWindowSize()); }
            window.addEventListener('resize', handleWindowResize);
            return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])

    const [showRegisterError,setshowRegisterError]=useState('')
    const onRequestOtp=async ()=>{
        if(isVerified)return
        if(!validateEmail(data?.email)){
            alert('電子郵件無效，請重新輸入');
            return
        }
        setisloading(true)
        let checkEmail = await checkIfEmailRegisteredEvent('673ed26f3fbb217bf5661441',data.email)
        if(checkEmail.errno){
            alert(checkEmail.message||'error')
            setisloading(false)
            return
        }
        if(checkEmail?.result!==false){
            setshowRegisterError('該郵箱已經登記過，如用同一信箱登記，將覆蓋您原先的登記信息，如確認修改，請繼續填寫。')
        }else{
            setshowRegisterError('')
        }

        let result = await requestOtpWithEmail(data.email)
        if(result.errno){
            alert(`${result?.message}||Error (70001)`);
            setisloading(false)
            return
        }
        setisShowOtpPopup(true)
        setisVerified(false)
        setcountdown(120)
        setisloading(false)
    }
    const cancelOtp=()=>{
        setisShowOtpPopup(false)
        setisVerified(false)
        setData({...data,token:null});
        setnum1('');setnum2('');setnum3('');setnum4('');setnum5('');setnum6('');
    }


    const handleLoginButton=async ()=>{
        const otp = `${num1}${num2}${num3}${num4}${num5}${num6}`
        if((!otp)||otp.length!==6){alert('驗證碼無效，請重新輸入');return;}
        
        setisloading(true)
        try{
            setisloading(true)
            let res = await loginWithEmailOtp(data.email,otp)
            if(res.errno){
                alert(res.message||'驗證碼無效，請重新輸入');
                setisloading(false)
                return
            }
            
            let token = res?.token?.token
            setData({...data,token});
            
            setisShowOtpPopup(false)
            setisVerified(true)
            setnum1('');setnum2('');setnum3('');setnum4('');setnum5('');setnum6('');
            setisloading(false)
           

        }catch(e){
            console.log(e)
            alert(e.message)
        }finally{
            setisloading(false)
        }
    }
    const handleOtpInput=(num)=>(e)=>{
        if(num===1){ setnum1(e.target.value);if(e.target.value.length>=1)ref2.current.focus();
        }else if(num===2){ setnum2(e.target.value);if(e.target.value.length>=1)ref3.current.focus();
        }else if(num===3){ setnum3(e.target.value);if(e.target.value.length>=1)ref4.current.focus();
        }else if(num===4){ setnum4(e.target.value);if(e.target.value.length>=1)ref5.current.focus();
        }else if(num===5){ setnum5(e.target.value);if(e.target.value.length>=1)ref6.current.focus();
        }else if(num===6){ setnum6(e.target.value);}
    }
    useEffect(()=>{
        let i = setInterval(()=>{setcountdown(c=>{return (c-1)<=0?0:c-1}) },1000)
        return ()=>{clearInterval(i)}
    },[])

    const [isShowSuccessfulPage,setisShowSuccessfulPage]=useState(false)
    const handleCompleteRegister=async ()=>{
        if(!isVerified){
            alert('請先透過發送驗證碼來驗證您的電子郵件')
            return
        } 
        if( !data.firstname ){ alert('請輸入您的名'); return }
        if( !data.lastname ){ alert('請輸入您的姓'); return }
        if( !data.mobile ){ alert('請輸入您的手機號'); return }
        if( !data.title ){ alert('請選擇您的性別'); return }
        if( !data.company ){ alert('請輸入您的公司/機構名稱'); return }
        if( !data.position ){ alert('請選擇您的職務'); return }
        if((!data.isAttendEvent1) && (!data.isAttendEvent2)){alert('請選擇出席環節'); return }
        if(!data.isAcknowledged){alert('您必須同意該聲明才能繼續'); return }
        if(data.firstname.length>=60 || data.lastname.length>=60 || data?.englishName>=60){alert('你的名字太長');return}




        setisloading(true)
        
        let json={
            firstname: data?.firstname,
			lastname:  data?.lastname,
        }
        if(data.englishName)json.englishName=data.englishName
        if(data.title){
            json.title=data.title==='男 Male'?'先生':'女士'
            json.gender = (data.title==='男 Male')?'男性':'女性'
        }
        // if(data.gender)json.gender=data.gender
        if(data.dateOfBirthMonth)json.dateOfBirthMonth=data.dateOfBirthMonth
        if(data.dateOfBirthDate)json.dateOfBirthDate=data.dateOfBirthDate
        if(data.position)json.position=data.position
        if(data.company)json.company=data.company
        //business profile
        json.businessProfile={email:data.email}
        if(data.mobile)json.businessProfile.mobile=data.mobile
        if(data.wechat)json.businessProfile.wechat=data.wechat
        let result = await uploadNewUserProfile(data?.token,json)
        if(result.errno){
            alert(`${JSON.stringify(result.message||"Error (70007)")}`)
            setisloading(false)
            return
        }else{
            let result2 = await participateEvent(data?.token,'673ed26f3fbb217bf5661441',{
                isAttendEvent1:data?.isAttendEvent1||false,
                isAttendEvent2:data?.isAttendEvent2||false,
                isRegisterTgather:data?.isRegisterTgather||false
            })
            if(result2.errno){
                alert(`${JSON.stringify(result2.message||"Error (70008)")}`)
                setisloading(false)
                return
            }else{
                setisShowSuccessfulPage(true)
            }
        }
        setisloading(false)
        

    }

    const [showChoosePopup,setshowChoosePopup]=useState(null)
    const toggleChoosePopup = (type)=>()=>{
        if(showChoosePopup===type) setshowChoosePopup(null)
        else
            setshowChoosePopup(type)
    }
    const handleSetTitle=(title)=>()=>{
        setData({...data,title})
        setshowChoosePopup(null)
    }
    const handleSetGender=(gender)=>()=>{
        setData({...data,gender})
        setshowChoosePopup(null)
    }
    const handleSetdateOfBirthMonth=(dateOfBirthMonth)=>()=>{
        setData({...data,dateOfBirthMonth})
        setshowChoosePopup(null)
    }
    const handleSetposition=(position)=>()=>{
        setData({...data,position})
        setshowChoosePopup(null)
    }
    const handleSetdateOfBirthDate=(dateOfBirthDate)=>()=>{
        setData({...data,dateOfBirthDate})
        setshowChoosePopup(null)
    }
    const getAvailableDate=()=>{
        let max= 31
        if(['2'].includes(data?.dateOfBirthMonth)) max =29
        if(['4','6','9','11',].includes(data?.dateOfBirthMonth)) max =30
        const valArr = []
        for(let i = 1; i <= max; i++){
            valArr.push(`${i}`)
        } 
        return valArr
    }
    const isReady=()=>{
        if(!isVerified)return false
        if(!data.firstname ){ return false }
        if(!data.lastname ){  return  false}
        if(!data.position){return  false}
        if(!data.title){return  false}
        if(!data.company){return  false}
        if(!data.mobile){return  false}
        if((!data.isAttendEvent1) && (!data.isAttendEvent2)){return false}
        if(!data.isAcknowledged){return  false}
        return true
    }

    if(isShowSuccessfulPage) return (
        <div className="w-full h-full flex flex-col items-center justify-start p-[16px] bg-[#ddd]" style={{overflowY:'auto'}}>
            
            <div className="relative w-full max-w-[1300px]">
                <div className="relative w-full border-white border-2 p-[24px] rounded-[50px] flex flex-col justify-start items-center mt-[64px] overflow-hidden">
                    <div className='absolute bottom-0 w-full h-full' style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 69%, rgba(255, 255, 255, 0.6) 87.5%, #FFFFFF 100%)',zIndex:1,}}/>
                    <div className="flex flex-col w-full items-center gap-[16px] text-center" style={{zIndex:2}}>
                        <div className="font-[350] text-[32px] text-[#6E604C]">第四屆香港國際⼈才⾼峰論壇</div>
                        <div className="font-[700] text-[32px] text-[#6E604C]">進場登記</div>
                        <CompleteSvg width={windowSize.innerWidth<=1024?220:286}/>

                        <div className="font-[400] d-text-28-m-22 text-[#6E604C] mt-[16px]">
                            {data?.lastname} {data?.firstname} {data?.englishName||""} {(data?.title==='男 Male'?'先生':data?.title==='女 Female'?'女士':'')}, 我們衷心期待你的光臨。
                        </div>
                        <div className="font-[400] d-text-28-m-22 text-[#6E604C] mt-[-16px]">
                            以下是你的登記資料
                        </div>
                        <div className="font-[400] text-[18px] text-[#6E604C] mt-[12px]">
                            電郵地址 &nbsp;&nbsp; {data?.email}
                        </div>
                        <div className="font-[350] d-text-28-m-22 text-[#6E604C] mt-[12px]">
                            您已登記成功，我們正在確認您的信息，並將於近日內發送確認電子郵件給您。
                        </div>
                        <div className="font-[700] d-text-36-m-24 text-[#0F737E] mt-[24px]">
                            入場時請使用T.Gather App進行入場登記
                        </div>
                        <div className="download-button max-w-full mt-[24px] flex justify-center items-center text-white d-text-32-m-24 font-[700] rounded-[99px]" style={{background:'linear-gradient(94.86deg, #263E54 5.53%, #0C6E7D 51.67%, #264554 94.27%)',border: '3px solid #C1C1C1'}}>
                            下載 T.Gather
                        </div>

                        <div className="flex flex-row items-center max-w-[600px]" style={{gap:8}}>
                            <div className="cursor-pointer" onClick={()=>{window.open('https://apps.apple.com/us/app/t-gather/id6738165815', '_blank');}}>
                                <img src='/apple.png' alt='apple'/>
                            </div>
                            <div className="cursor-pointer" onClick={()=>{window.open('https://play.google.com/store/apps/details?id=com.aquila.tgather', '_blank');}}>
                                <img src='/google.png' alt='apple'/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
    return(
        <div className="w-full h-full flex flex-col items-center justify-start p-[16px] bg-[#ddd]" style={{overflowY:'auto'}}>
            
            <div className="relative w-full max-w-[1300px]">
            <div className="relative w-full border-white border-2 d-pad-24-m-16 rounded-[50px] flex flex-col justify-center items-center mt-[64px] overflow-hidden">
                <div className='absolute bottom-0 w-full h-full' style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 69%, rgba(255, 255, 255, 0.6) 87.5%, #FFFFFF 100%)',zIndex:1,}}/>
                <div className="flex flex-col w-full items-center gap-[16px]" style={{zIndex:2}}>
                    <div className="font-[350] d-text-32-m-20 text-[#6E604C] text-center">第四屆香港國際⼈才⾼峰論壇</div>
                    <div className="font-[700] d-text-32-m-20 text-[#6E604C] text-center">進場登記</div>
                    {/* <div className="font-[400] text-[13px] text-[#473F3A] text-center">請提供更多關於你的信息, 您提供的個人資訊將用於您的 T.Gather 會員註冊</div> */}
                    <div className="font-[400] text-[13px] text-[#473F3A] text-center">
                        {/* 請提供更多關於你的信息, 您提供的個人資訊將用於⾼峰論壇及 T.Gather 會員註冊 */}
                        請提供您的更多信息，用於第四屆香港國際人才高峰論壇的報名登記
                    </div> 
            
                    <div className="w-full flex flex-col items-start gap-[8px] max-w-[800px] pt-[8px]">
                        <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">電郵 <div className="text-[12px]">(Email)</div></div>
                        <div className="flex flex-row w-full gap-[12px]">
                            <div className="flex-1 pb-[8px] h-full" style={{borderBottom:'1px solid #00000033'}}>
                                <input disabled={isVerified} onChange={onChangeEmail} value={data?.email||''} className="w-full bg-[transparent]" placeholder="請填寫"/>
                            </div>

                            <div onClick={onRequestOtp}  className="w-[140px] h-[39px] cursor-pointer d-flex-m-hidden justify-center items-center text-white text-[14px] font-[500] rounded-[99px]" style={{opacity:isVerified?.5:1,background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                                發送驗證碼
                            </div>
                        </div>
                    </div>
                    <div onClick={onRequestOtp} className="max-w-[800px] w-[100%] h-[39px] cursor-pointer d-hidden-m-flex justify-center items-center text-white text-[14px] font-[500] rounded-[99px]" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                        發送驗證碼
                    </div>
                    {showRegisterError&&
                        <div className="text-[red] font-[400] text-[14px] flex flex-row items-left w-full max-w-[800px] ">
                            {showRegisterError}
                        </div>
                    }

                    <div className="relative w-full flex flex-col items-start gap-[8px] max-w-[800px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                        <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                            性別 <div className="text-[12px]">(Gender)</div>
                            {/* <div className="text-[12px] text-[#00000033]">(可選填)</div> */}
                        </div>
                        <div onClick={toggleChoosePopup('title')} className="flex flex-row w-full gap-[24px] items-center cursor-pointer">
                            <div className="font-[400] text-[18px] flex-1" style={{color:data?.title?'black':"#00000033"}}>{data?.title||'請選擇'}</div>
                            <DownArrow/>
                        </div>
                        {showChoosePopup==='title'&&<div className="absolute w-full top-[100%] z-[10] bg-white flex flex-col">
                            {['男 Male', '女 Female'].map(item=>
                                <div onClick={handleSetTitle(item)} key={item} className="text-[#000] font-[400] text-[18px] hover:font-[700] cursor-pointer px-[24px] py-[8px]">
                                    {item}
                                </div>
                            )}
                        </div>}
                    </div>

                    <div className="w-full flex flex-row max-w-[800px] flex-wrap">
                        <div className="d-flex1-m-100 flex flex-col items-start gap-[8px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                中文名
                                <div className="text-[12px]">(Chinese Firstname)</div>
                            </div>
                            <div className="flex flex-row w-full gap-[24px]">
                                <input value={data.firstname||''} onChange={onChangefirstname} className="flex-1 bg-[transparent]" placeholder="如無請填寫 N/A"/>
                            </div>
                        </div>
                        <div className="d-flex1-m-100 flex flex-col items-start gap-[8px] py-[8px] d-ml8-m-0" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                中文姓
                                <div className="text-[12px]">(Chinese Surname)</div>
                            </div>
                            <div className="flex flex-row w-full gap-[24px]">
                                <input value={data.lastname||''} onChange={onChangelastname} className="flex-1 bg-[transparent]" placeholder="如無請填寫 N/A"/>
                            </div>
                        </div>
                        <div className="d-flex2-m-100 flex flex-col items-start gap-[8px] py-[8px] d-ml8-m-0" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                英文名
                                <div className="text-[12px]">(English Name)</div>
                                <div className="text-[12px] text-[#00000033]">(可選填)</div>
                            </div>
                            
                            <div className="flex flex-row w-full gap-[24px]">
                                <input value={data.englishName||''} onChange={onChangeenglishName} className="flex-1 bg-[transparent]" placeholder="請填寫"/>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-row max-w-[800px] flex-wrap">
                        <div className="w-full flex flex-col items-start gap-[8px] max-w-[800px]]">
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">手機號 <div className="text-[12px]">(Mobile)</div></div>
                            <div className="flex flex-row w-full gap-[12px]">
                                <div className="flex-1 pb-[8px] h-full" style={{borderBottom:'1px solid #00000033'}}>
                                    <input onChange={onChangeMobile} value={data?.mobile||''} className="w-full bg-[transparent]" placeholder="請填寫"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-row max-w-[800px] flex-wrap">
                        <div className="d-flex2-m-100 flex flex-col items-start gap-[8px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                公司/機構名稱 <div className="text-[12px]">(Company/Institution Name)</div>
                                {/* <div className="text-[12px] text-[#00000033]">(可選填)</div> */}
                            </div>
                            
                            <div className="flex flex-row w-full gap-[24px]">
                                <input value={data.company||''} onChange={onChangecompany} className="flex-1 bg-[transparent]" placeholder="請填寫"/>
                            </div>
                        </div>
                       <div className="relative d-flex2-m-100 flex flex-col items-start gap-[8px] py-[8px] d-ml8-m-0" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                職務 <div className="text-[12px]">(Title)</div>
                            </div>
                            
                            <div className="flex flex-row w-full gap-[24px]">
                                <input value={data.position||''} onChange={onChangeeposition} className="flex-1 bg-[transparent]" placeholder="請填寫"/>
                            </div>


                            {/* <div onClick={toggleChoosePopup('position')} className="flex flex-row w-full gap-[24px] items-center cursor-pointer">
                                <div className="font-[400] text-[18px] flex-1" style={{color:data?.position?'black':"#00000033"}}>{data?.position||'請選擇'}</div>
                                <DownArrow/>
                            </div> */}
                            {/* {showChoosePopup==='position'&&<div className="absolute w-full top-[100%] z-[10] bg-white flex flex-col max-h-[200px] overflow-auto" >
                                {POSITIONS.map(item=>
                                    <div onClick={handleSetposition(item)} key={item} className="text-[#000] font-[400] text-[12px] hover:font-[700] cursor-pointer px-[24px] py-[8px]">
                                        {item}
                                    </div>
                                )}
                            </div>} */}

                        </div>
                    </div>


                    <div className="w-full flex flex-row max-w-[800px] flex-wrap">
                        {/* <div className="relative d-flex2-m-100 flex flex-col items-start gap-[8px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                性別
                                <div className="text-[12px] text-[#00000033]">(可選填)</div>
                            </div>
                            <div onClick={toggleChoosePopup('gender')} className="flex flex-row w-full gap-[24px] items-center cursor-pointer">
                                <div className="font-[400] text-[18px] flex-1" style={{color:data?.gender?'black':"#00000033"}}>{data?.gender||'請選擇'}</div>
                                <DownArrow/>
                            </div>
                            {showChoosePopup==='gender'&&<div className="absolute w-full top-[100%] z-[10] bg-white flex flex-col">
                                {['男性', '女性', '其他'].map(item=>
                                    <div onClick={handleSetGender(item)} key={item} className="text-[#000] font-[400] text-[18px] hover:font-[700] cursor-pointer px-[24px] py-[8px]">
                                        {item}
                                    </div>
                                )}
                            </div>}
                        </div> */}
                        <div className="relative flex-1 d-flex2-m-100 flex-col items-start gap-[8px] py-[8px] mr-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                生日月份 <div className="text-[12px]">(Birthday month)</div>
                                <div className="text-[12px] text-[#00000033]">(可選填)</div>
                            </div>
                            <div onClick={toggleChoosePopup('dateOfBirthMonth')} className="flex flex-row w-full gap-[24px] items-center cursor-pointer">
                                <div className="font-[400] text-[18px] flex-1" style={{color:data?.dateOfBirthMonth?'black':"#00000033"}}>{data?.dateOfBirthMonth||'月'}</div>
                                <DownArrow/>
                            </div>
                            {showChoosePopup==='dateOfBirthMonth'&&<div className="absolute w-full top-[100%] z-[10] bg-white flex flex-col max-h-[200px] overflow-auto" >
                                {['1', '2', '3','4','5','6','7','8','9','10','11','12'].map(item=>
                                    <div onClick={handleSetdateOfBirthMonth(item)} key={item} className="text-[#000] font-[400] text-[18px] hover:font-[700] cursor-pointer px-[24px] py-[8px]">
                                        {item}
                                    </div>
                                )}
                            </div>}
                        </div>
                        <div className="relative flex-1 d-flex2-m-100 flex-col items-start gap-[8px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                生日日期 <div className="text-[12px]">(Birthday date)</div>
                                <div className="text-[12px] text-[#00000033]">(可選填)</div>
                            </div>
                            <div onClick={toggleChoosePopup('dateOfBirthDate')} className="flex flex-row w-full gap-[24px] items-center cursor-pointer">
                                <div className="font-[400] text-[18px] flex-1" style={{color:data?.dateOfBirthDate?'black':"#00000033"}}>{data?.dateOfBirthDate||'日'}</div>
                                <DownArrow/>
                            </div>
                            {showChoosePopup==='dateOfBirthDate'&&<div className="absolute w-full top-[100%] z-[10] bg-white flex flex-col max-h-[200px] overflow-auto" >
                                {getAvailableDate().map(item=>
                                    <div onClick={handleSetdateOfBirthDate(item)} key={item} className="text-[#000] font-[400] text-[18px] hover:font-[700] cursor-pointer px-[24px] py-[8px]">
                                        {item}
                                    </div>
                                )}
                            </div>}
                        </div>
                    </div>
                    <div className="w-full flex flex-row max-w-[800px] flex-wrap">
                        <div className="d-flex2-m-100 flex flex-col items-start gap-[8px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                微信號 <div className="text-[12px]">(Wechat ID)</div>
                                <div className="text-[12px] text-[#00000033]">(可選填)</div>
                            </div>
                            
                            <div className="flex flex-row w-full gap-[24px]">
                                <input value={data.wechat||''} onChange={onChangeWechat} className="flex-1 bg-[transparent]" placeholder="請填寫"/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="w-full flex flex-row max-w-[800px] flex-wrap">
                        <div className="d-flex2-m-100 flex flex-col items-start gap-[8px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                                出席環節 <div className="text-[12px]">(Attendance Session)</div>
                                <div className="text-[12px] text-[#00000033]">(可多選Multiple choices)</div>
                            </div>
                            
                            <div className="flex flex-row w-full gap-[24px]">
                                <input type="checkbox" checked={data?.isAttendEvent1||false} onChange={(e)=>{setData({...data,isAttendEvent1:!data.isAttendEvent1})}}/> 
                                1、展覽招聘（12月13日 13:00）
                            </div>
                            <div className="flex flex-row w-full gap-[24px]">
                                <input type="checkbox" checked={data?.isAttendEvent2||false} onChange={(e)=>{setData({...data,isAttendEvent2:!data.isAttendEvent2})}}/> 
                                2、論壇活動（12月13日 14:00）
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-row max-w-[800px] flex-wrap">
                        <div className="d-flex2-m-100 flex flex-col items-start gap-[8px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>

                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                            註冊T.Gather會員 <div className="text-[12px]">(T.Gather Registration)</div>
                            </div>
                            <div className="flex flex-row w-full gap-[24px]">
                                <input type="checkbox" checked={data?.isRegisterTgather||false} onChange={(e)=>{setData({...data,isRegisterTgather:!data.isRegisterTgather})}}/> 
                                同時註冊成為T.Gather會員，利用QR code 進場
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-row max-w-[800px] flex-wrap">
                        <div className="d-flex2-m-100 flex flex-col items-start gap-[8px] py-[8px]" style={{borderBottom:'1px solid #00000033'}}>
                            <div className="text-[#6E604C] font-[400] text-[18px] flex flex-row items-end">
                            聲明 <div className="text-[12px]">(Statement)</div>
                            </div>
                            
                            <div className="flex flex-row w-full gap-[24px] text-[11px]">
                                <input type="checkbox" checked={data?.isAcknowledged||false} onChange={(e)=>{setData({...data,isAcknowledged:!data.isAcknowledged})}}/> 
                                本人謹此聲明，本人就此項申請所提供之一切資料均屬真實有效，如有虛報本人之申請將會被取消。本人授權香港國際人才高峰論壇使用本人提供之資料作統計、活動等指定用途。
                                <br/>I hereby declare that all the information I have provided for this application is true and valid. If there is any false declaration, my application will be cancelled. I authorize the Hong Kong International Talent Summit Forum to use the infomation provided by me for designated purposes such as statistics.
                            </div>
                        </div>
                    </div>
                    

                    <div onClick={handleCompleteRegister} className="max-w-full register-button mt-[16px] cursor-pointer flex justify-center items-center text-white d-text-24-m-14 font-[700] rounded-[99px]" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5',opacity:isReady()?1:.3}}>
                        登記 (Register)
                    </div>

                    
                </div>            
            </div>
            </div>


            {isShowOtpPopup&&<div className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000070] flex items-center justify-center p-[16px] z-[3]">
                <div className="flex flex-col w-full max-w-[800px] bg-[#fff] rounded-[24px] d-px64-m-px-16 py-[32px] justify-center items-center" style={{boxSizing:'border-box'}}>
                    <div className="text-[#6E604C] text-[32px] font-[700]">驗證</div>
                    <div className="flex flex-row text-[#938474] font-[400] text-[18px] w-full text-left mt-[64px]"> 
                        驗證碼已發送至
                        <div className="ml-[16px] font-[700] d-flex-m-hidden">{data?.email||''}</div>
                    </div>
                    <div className="font-[700] d-hidden-m-flex text-[#938474] text-[12px] text-left w-full">{data?.email||''}</div>

                    <div className='w-full flex flex-row justify-start mt-5 ' style={{gap:8,boxSizing:'border-box'}}>
                        <div className='flex-[1]' style={{height:undefined,aspectRatio:1,}}>
                            <input value={num1} onChange={handleOtpInput(1)} ref={ref1} className='w-full h-full text-[32px] text-center' maxLength={1} style={{borderColor:'#8D876C',borderWidth:.5,borderRadius:8,boxSizing:'border-box'}}/>
                        </div>
                        <div className='flex-[1]' style={{height:undefined,aspectRatio:1,}}>
                            <input value={num2} onChange={handleOtpInput(2)} ref={ref2} className='w-full h-full text-[32px] text-center' maxLength={1} style={{borderColor:'#8D876C',borderWidth:.5,borderRadius:8,boxSizing:'border-box'}}/>
                        </div>
                        <div className='flex-[1]' style={{height:undefined,aspectRatio:1,}}>
                            <input value={num3} onChange={handleOtpInput(3)} ref={ref3} className='w-full h-full text-[32px] text-center' maxLength={1} style={{borderColor:'#8D876C',borderWidth:.5,borderRadius:8,boxSizing:'border-box'}}/>
                        </div>
                        <div className='flex-[1]' style={{height:undefined,aspectRatio:1,}}>
                            <input value={num4} onChange={handleOtpInput(4)} ref={ref4} className='w-full h-full text-[32px] text-center' maxLength={1} style={{borderColor:'#8D876C',borderWidth:.5,borderRadius:8,boxSizing:'border-box'}}/>
                        </div>
                        <div className='flex-[1]' style={{height:undefined,aspectRatio:1,}}>
                            <input value={num5} onChange={handleOtpInput(5)} ref={ref5} className='w-full h-full text-[32px] text-center' maxLength={1} style={{borderColor:'#8D876C',borderWidth:.5,borderRadius:8,boxSizing:'border-box'}}/>
                        </div>
                        <div className='flex-[1]' style={{height:undefined,aspectRatio:1,}}>
                            <input value={num6} onChange={handleOtpInput(6)} ref={ref6} className='w-full h-full text-[32px] text-center' maxLength={1} style={{borderColor:'#8D876C',borderWidth:.5,borderRadius:8,boxSizing:'border-box'}}/>
                        </div>
                    </div>

                    <div className='flex flex-row w-full items-center justify-between mt-5'>
                        {countdown>0
                            ?<div className='text-[#949494] text-[14px]'>{countdown}秒後重新發送</div>
                            :<div onClick={onRequestOtp} className='underline text-[14px] text-[#6E604C] cursor-pointer'>重新發送</div>
                        }
                    </div>

                    <div onClick={handleLoginButton} className="d-flex-m-hidden h-[72px] bg-[#0F737E] w-full rounded-[99px] text-white text-[24px] font-[500] text-center flex items-center justify-center cursor-pointer mt-[64px]">
                        確認
                    </div>
                    <div onClick={cancelOtp} className="d-flex-m-hidden h-[72px] bg-[#fff] w-full rounded-[99px] text-[#0F737E] text-[24px] font-[500] text-center flex items-center justify-center cursor-pointer mt-[4px]">
                        取消
                    </div>
                    <div onClick={handleLoginButton} className="d-hidden-m-flex h-[48px] bg-[#0F737E] w-full rounded-[99px] text-white text-[16px] font-[500] text-center flex items-center justify-center cursor-pointer mt-[64px]">
                        確認
                    </div>
                    <div onClick={cancelOtp} className="d-hidden-m-flex h-[48px] bg-[#fff] w-full rounded-[99px] text-[#0F737E] text-[16px] font-[500] text-center flex items-center justify-center cursor-pointer mt-[4px]">
                        取消
                    </div>

                </div>
            </div>}
            
            {isloading&&<LoadingPage/>}

        </div>
    )
}