import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={10}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeOpacity={0.2}
      strokeWidth={2}
      d="m1 1 7.815 7.355a1 1 0 0 0 1.37 0L18 1"
    />
  </svg>
)
export default SvgComponent
