import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useCookies } from "react-cookie";
import { COOKIESPREFIX } from "../config";
import { validateEmail } from "../utils/util";
import { merchantLoginWithPassword } from "../services/users";
import {merchantAddRemarks, merchantChangeParticipationStatus, merchantFetchParticipationWithUserData} from '../services/events';
import { Login } from "../actions/actions";
import PageSelector from '../components/PageSelector';
import LoadingPage from "../components/Loading";
import DropdownMenu from '../components/DropdownMenu'
import {utils,writeFile} from 'xlsx';

const limit = 10
const eventId= '673ed26f3fbb217bf5661441'
function Page(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo=(str)=>()=>{
        navigate(str)
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
        useEffect(()=>{
            function handleWindowResize() {setWindowSize(getWindowSize()); }
            window.addEventListener('resize', handleWindowResize);
            return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])

    const [cookies, setCookie, removeCookie] = useCookies([`${COOKIESPREFIX}_cred`,`${COOKIESPREFIX}_token`]);
    const [email,setEmail]=useState('');
    const [isloading,setisloading]=useState(false);
    const handleEmailInput=(e)=>{setEmail(e.target.value)};
    const [password,setPassword]=useState('');
    const handlePasswordInput=(e)=>{setPassword(e.target.value)};

    const handleLogin=async ()=>{
        if(!validateEmail(email)){
            alert('Invalid Email');return
        }
        if(!password){
            alert('Invalid');return
        }
        try{
            let res =  await merchantLoginWithPassword(email,password)
            if(res.errno){
                alert(res.message||'Invalid');
                return
            }
            
            let token = res?.token?.token
            let userId = res?.token?.userId
            props.dispatch(Login(true,token,'merchant',email,userId))
     
            setCookie(`${COOKIESPREFIX}_cred`, {email:email,usertype: 'merchant'}, { path: '/' });
            setCookie(`${COOKIESPREFIX}_token`, token, { path: '/', expires: new Date(Date.now()+1000*60*60*24*7)}); //7 days

        }catch(e){
            alert(e.message)
        }finally{
            setisloading(false)
        }
    }
    useEffect(()=>{
        if(props?.token?.usertype&&props?.token?.usertype!=='merchant'){
            props.dispatch(Login(false))
        }
    },[props.token])






    const [searchParams, setSearchParams] = useSearchParams();
    const [totalItem, setTotalItem] = useState(1)
    const totalPage = Math.ceil(totalItem/limit)||1
    const [data,setData]=useState([])
    const page =  parseInt(searchParams.get('page'))||1
    const filterLastname = searchParams.get('lastname')
    const filterFirstname = searchParams.get('firstname')
    const filterEmail = searchParams.get('email')
    const filterRemark1 = searchParams.get('remark1')
    const filterType = searchParams.get('type')||'全部'
    //
    const handleSearchLastname=(e)=>{setSearchParams({page:1,lastname:e.target.value})}
    const handleSearchFirstname=(e)=>{setSearchParams({page:1,firstname:e.target.value})}
    const handleSearchEmail=(e)=>{setSearchParams({page:1,email:e.target.value})}
    const handleSearchRemark1=(e)=>{setSearchParams({page:1,remark1:e.target.value})}
    useEffect(() => {
        if(!props.token.isLogin)return
        const timer = setTimeout(fetchData, 500)
        const interval = setInterval(fetchData,1000*30)
        return () => {
            clearTimeout(timer)
            clearInterval(interval)
        }
    },[searchParams,props.token])
    async function fetchData(){
        // formatDataForChart()
        const filter = {eventId}
        const userFilter ={}
        if(filterType==='待批准'){
            filter.status='PENDING'
        }else if(filterType==='已拒絕'){
            filter.status='REJECTED'
        }else if(filterType==='已核准'){
            filter.status='APPROVED'
        }
            //'待批准','已核准','已拒絕'
        if(filterLastname) userFilter['user.data.lastname'] = {"$regex": filterLastname, "$options":'i'}
        if(filterFirstname) userFilter['user.data.firstname'] = {"$regex": filterFirstname, "$options":'i'}
        if(filterEmail) userFilter['user.email'] = {"$regex": filterEmail, "$options":'i'}
        if(filterRemark1) filter['remarks.remark1'] = {"$regex": filterRemark1, "$options":'i'}
        const res = await merchantFetchParticipationWithUserData(props.token.token, filter, {limit,skip:limit*(page-1),sort:{_id:-1}},userFilter)
        if(res.errno) return
        setTotalItem(res.count)
        setData(res.result)

        const toPage = Math.ceil(res.count/limit) || 1
        if(page > toPage) handleParamChange('page', 1)
    }
    const handleSelectPage=(p)=>()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(val !== '') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function selectFilter(item,index){
        searchParams.delete('type')
        searchParams.set('type', item)
        setSearchParams(searchParams)
    }

    const handleApprove=(item,status)=>async()=>{
        setisloading(true)
        let result = await merchantChangeParticipationStatus(props.token?.token,item?.user?._id,eventId,status)
        if(result?.errno){
            alert(`${result?.message||'error'}`)
        }else{
            await fetchData()
        }
        setisloading(false)
    }
    const handleApproveAll=async()=>{
        let hasError= false
        setisloading(true)
        for(let item of data){
            if(item.isSelected&&item.status!=='APPROVED'){
                let result = await merchantChangeParticipationStatus(props.token?.token,item?.user?._id,eventId,'APPROVED')
                if(result?.errno){
                    console.log(`${result?.message||'error'}`)
                    hasError=`${result?.message||'error'}`
                }
            }
        }
        await fetchData()
        setisloading(false)
        if(hasError)
            alert(hasError)
    }
    const handleRejectAll=async()=>{
        let hasError= false
        setisloading(true)
        for(let item of data){
            if(item.isSelected&&item.status!=='REJECTED'){
                let result = await merchantChangeParticipationStatus(props.token?.token,item?.user?._id,eventId,'REJECTED')
                if(result?.errno){
                    console.log(`${result?.message||'error'}`)
                    hasError=`${result?.message||'error'}`
                }
            }
        }
        await fetchData()
        setisloading(false)
        if(hasError)
            alert(hasError)
    }


    // 'PENDING':"PENDING",
    // 'REJECTED':'REJECTED',
    // 'APPROVED':"APPROVED"

    const handleSaveImage = (imageUrl) => {
        const link = document.createElement('a');
        link.target="_blank"
        link.href = imageUrl;
        link.download = 'qrcode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [isAddingRemarks,setisAddingRemarks]=useState(null)
    const handleAddRemarks=async ()=>{
        setisloading(true)
        let result = await merchantAddRemarks(props.token?.token,isAddingRemarks?.user?._id,eventId,isAddingRemarks.remarks)
        if(result?.errno){
            alert(`${result?.message||'error'}`)
        }else{
            setisAddingRemarks(null)
            await fetchData()
        }
        setisloading(false)
    }

    const handleCancelRemarks=()=>{
        setisAddingRemarks(null)
    }

    const handleDownload=async ()=>{
        setisloading(true)
        const res = await merchantFetchParticipationWithUserData(props.token.token, {eventId}, {limit:99999,sort:{_id:-1}},{})
        if(res.errno||!res.result) {
            alert('Download Error')
        }else{
            let data = res.result
            console.log(data)

            const array = [
                ['電郵地址(Email)','手機號(Mobile)', '性別(Gender)','姓(Surname)','名(Firstname)','英文名(English Name)','公司/機構名稱(Company)','職務(Title)','審批狀態','出席狀態',' 微信號(Wechat ID)','備註 1','備註 2','出席環節1','出席環節2','下載二維碼'],
            ];
                            

            for(let item of data){
                array.push([
                    item?.user?.email||'',
                    item?.user?.data?.businessProfile?.mobile||'',
                    item?.user?.data?.gender||'',
                    item?.user?.data?.lastname||'',
                    item?.user?.data?.firstname||'',
                    item?.user?.data?.englishName||'',
                    item?.user?.data?.company||'',
                    item?.user?.data?.position||'',
                    item?.status==='APPROVED'?'已核准':item?.status==='REJECTED'?'已拒絕':'待批准',
                    item?.signInTime?'已參會':'-',
                    item?.user?.data?.businessProfile?.wechat||'',
                    item?.remarks?.remark1||'',
                    item?.remarks?.remark2||'',
                    item?.data?.isAttendEvent1?'出席':'不出席',
                    item?.data?.isAttendEvent2?'出席':'不出席',
                    item?.s3url||''
                ])
            }

    
            const ws = utils.aoa_to_sheet(array);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, '參與者訊息');
            writeFile(wb, '第四屆香港國際⼈才⾼峰論壇參與者訊息.xlsx');
                

        }
        setisloading(false)
    }

    if(!props.token?.isLogin){
        return(
            <div className="w-full h-full flex flex-col items-center justify-center bg-[#ddd] overflow-hidden">
                <div className="text-[#6E604C]">第四屆香港國際⼈才⾼峰論壇 - 登記核准</div>
                <div className="relative max-w-[400px] w-full border-white border-2 d-pad-24-m-16 rounded-[24px] flex flex-col justify-center items-center mt-[24px] overflow-hidden">
                    <div className='absolute bottom-0 w-full h-full' style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 69%, rgba(255, 255, 255, 0.6) 87.5%, #FFFFFF 100%)',zIndex:1,}}/>
                    <div className="text-[#6E604C] font-[700] text-[24px]">登入</div>
                    <div className="font-bold mt-[24px] w-full text-left text-[#6E604C] z-[2">電郵地址</div>
                    <div className='w-full mt-[4px] z-[2]' style={{borderBottom:'1px solid black',padding:'8px 12px'}}>
                        <input value={email} onChange={handleEmailInput} name='email' className='w-full' style={{border:'none',background:"transparent"}}  placeholder='請輸入電郵地址'/> 
                    </div>
                    <div className="font-bold mt-[24px] w-full text-left text-[#6E604C] z-[2">密碼</div>
                    <div className='w-full mt-[4px] z-[2]' style={{borderBottom:'1px solid black',padding:'8px 12px'}}>
                        <input value={password} onChange={handlePasswordInput} type='password' name='password' className='w-full' style={{border:'none',background:"transparent"}}  placeholder='請填寫密碼'/> 
                    </div>
                    <div onClick={handleLogin} className="w-[100%] min-h-[50px] cursor-pointer z-[2] flex justify-center items-center text-white text-[16px] font-[700] rounded-[99px] mt-[64px] mb-[24px]" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                        登入
                    </div>
                </div>        
            </div>
        )
    }


    return(
        <div className="w-full h-full flex flex-col items-center justify-start bg-[#ddd] overflow-hidden p-[16px]">
            
            <div className="text-[#6E604C]">第四屆香港國際⼈才⾼峰論壇 - 登記核准</div>
            <div className="w-full flex flex-row gap-[16px] mt-[24px]">
                <input onChange={handleSearchLastname} placeholder="按姓氏搜尋" className="p-[4px] rounded-[4px]"/>
                <input onChange={handleSearchFirstname} placeholder="按名字搜尋" className="p-[4px] rounded-[4px]"/>
                <input onChange={handleSearchEmail} placeholder="透過電子郵件搜尋" className="p-[4px] rounded-[4px]"/>
                <input onChange={handleSearchRemark1} placeholder="透過備註 1搜尋" className="p-[4px] rounded-[4px]"/>
                <div className="flex flex-row flex-1 justify-end items-center" style={{gap:8}}>
                    <div onClick={handleApproveAll} className="text-[10px] cursor-pointer bg-[white] p-[4px] rounded-[4px] text-[green] border-1 border border-[green]">核准所有已選擇的參與者</div>
                    <div onClick={handleRejectAll} className="text-[10px] cursor-pointer bg-[white] p-[4px] rounded-[4px] text-[#A15454] border-1 border border-[#A15454]">拒絕所有已選擇的參與者</div>
                    <div onClick={handleDownload} className="text-[10px] cursor-pointer bg-[white] p-[4px] rounded-[4px] border-1 border border-black">下載所有參與者訊息</div>
                    <DropdownMenu value={filterType} select={selectFilter} options={['全部','待批准','已核准','已拒絕']}/>
                </div>
            </div>
            <div className="flex-1 w-full flex flex-col py-[16px]" style={{overflowY:'auto'}}>
                {/*Header */}
                <div className="flex flex-row justify-betwen items-center w-full text-[12px] text-[#6E604C] font-[700] pb-[8px]" style={{borderBottom:'2px solid #6E604C'}}>
                    <div className="w-[24px] h-[24px] flex flex-row items-center">
                        <input type="checkbox" checked={data?.every(p=>p.isSelected)} onChange={(e)=>{
                            let isAllSelected = data?.every(p=>p.isSelected)
                            let d= [...data];
                            for(let i of data){
                                i.isSelected= isAllSelected?false:true
                            }
                            setData(d)
                        }}/> 
                    </div>
                    <div className="flex-[2]">電郵地址(Email)</div>
                    <div className="flex-1">性別(Gender)</div>
                    <div className="flex-1">姓(Surname)</div>
                    <div className="flex-1">名(Firstname)</div>
                    <div className="flex-1">英文名(English Name)</div>
                    <div className="flex-[2]">公司/機構名稱(Company)</div>
                    <div className="flex-[2]">職務(Title)</div>
                    <div className="flex-[2]">審批狀態</div>
                    <div className="flex-[1]">出席狀態</div>
                    <div className="flex-[1]">備註 1</div>
                    <div className="flex-[1]">備註 2</div>
                    <div className="w-[48px] h-[24px]"></div>
                    <div className="flex-[1]">報名時間</div>
                    <div className="flex-1">下載二維碼</div>
                </div>

                <div className="flex-1 w-full flex flex-col mt-[16px]">
                    {data?.map((item,index)=>
                        <div key={item?._id} className="flex flex-row justify-betwen items-center w-full text-[12px] text-[black] font-[400] py-[8px]" style={{borderBottom:'.5px solid #fff', backgroundColor:item.isSelected?'#ccc':'transparent'}}>
                            <div className="w-[24px] h-[24px] flex flex-row items-center">
                                <input type="checkbox" checked={item.isSelected} onChange={(e)=>{
                                    let d= [...data];
                                    for(let i of data){
                                        if(i._id===item._id) i.isSelected = !i.isSelected
                                    }
                                    setData(d)
                                }}/> 
                            </div>
                            <div className="flex-[2]">{item?.user?.email||''}</div>
                            <div className="flex-1">{item?.user?.data?.gender||''}</div>
                            <div className="flex-1">{item?.user?.data?.lastname||''}</div>
                            <div className="flex-1">{item?.user?.data?.firstname||''}</div>
                            <div className="flex-1">{item?.user?.data?.englishName||''}</div>
                            <div className="flex-[2]">{item?.user?.data?.company||''}</div>
                            <div className="flex-[2]">{item?.user?.data?.position||''}</div>
                            <div className="flex-[2] flex flex-row justify-start items-center">{
                                item?.status==='APPROVED'?<div className="flex flex-row items-center gap-[8px]">
                                    已核准
                                    <div className="cursor-pointer" onClick={handleApprove(item,"PENDING")}><ReloadSvg/></div>
                                </div>
                                :item?.status==='REJECTED'?<div className="flex flex-row items-center gap-[8px]">
                                    已拒絕
                                    <div className="cursor-pointer" onClick={handleApprove(item,"PENDING")}><ReloadSvg/></div>
                                </div>
                                :<div className="flex flex-row gap-[8px]">
                                    <div className='text-[#717171] text-[13px] font-[500] w-[60px] bg-white text-center py-[4px] rounded-[99px] cursor-pointer' onClick={handleApprove(item,"APPROVED")}>核准</div>
                                    <div className='text-[#A15454] border-[#A15454] border-[1px] text-[13px] font-[500] w-[60px] bg-[#CBCBCB] text-center py-[4px] rounded-[99px] cursor-pointer' onClick={handleApprove(item,"REJECTED")}>拒絕</div>
                                </div>
                            }</div>
                            <div className="flex-[1]">{item?.signInTime?"已參會":'-'}</div>
                            <div className="flex-[1]">{item?.remarks?.remark1||''}</div>
                            <div className="flex-[1] flex flex-row">{item?.remarks?.remark2||''}
                            </div>
                            <div className="w-[48px]">
                                <div className="cursor-pointer" onClick={()=>{setisAddingRemarks(JSON.parse(JSON.stringify(item)))}}>
                                    <img src='/edit.png' className="w-[18px] h-[18px]" alt='edit icon'/>
                                </div>
                            </div>
                            <div className="flex-[1]">{new Date(item?.createDate).toLocaleString()}</div>
                            <div className="flex-1">
                                {item?.s3url?
                                <div className="cursor-pointer" onClick={()=>{handleSaveImage(item?.s3url)}}>
                                    <img alt='qrcode' src='/qr-code.png' className="w-[24px] h-[24px]"/>
                                </div>:<div/>}
                            </div>
                        </div>
                    )}
                </div>
            </div>

         
            <PageSelector page={page} total={totalItem} totalPage={totalPage} handleSelectPage={handleSelectPage}/>
            {isAddingRemarks&&
                <div className="absolute top-0 bottom-0 left-0 right-0 bg-[#00000090] max-h-[100%] z-[100] flex justify-center items-center">
                    <div className="absolute top-0 bottom-0 left-0 right-0 z-[101]"/>
                    <div className="max-w-[800px] max-h-[90%] w-[90%] p-[24px] bg-[#B9B7AE] rounded-[24px] flex flex-col jusitfy-start items-center z-[102]" style={{overflowY:'auto'}}>
                        <div className="text-black text-[24px] font-[700]">備註</div>
                        <div className="flex flex-row text-black text-[18px] font-[400] mt-[48px]">
                            備註 1:
                            <input value={isAddingRemarks?.remarks?.remark1||''} className='px-[8px] ml-[8px] rounded-[6px]' onChange={(e)=>{
                                let d={...isAddingRemarks}
                                d.remarks={...d.remarks||{},remark1:e.target.value}
                                setisAddingRemarks(d)
                            }}/>
                        </div>
                        <div className="flex flex-row text-black text-[18px] font-[400] mt-[16px]">
                            備註 2:
                            <input value={isAddingRemarks?.remarks?.remark2||''} className='px-[8px] ml-[8px] rounded-[6px]' onChange={(e)=>{
                                let d={...isAddingRemarks}
                                d.remarks={...d.remarks||{},remark2:e.target.value}
                                setisAddingRemarks(d)
                            }}/>
                        </div>

                        <div onClick={handleAddRemarks} className="cursor-pointer bg-[#0F737E] mt-[48px] text-[20px] font-[500] w-full flex items-center justify-center p-[12px] rounded-[99px] text-[white]">
                            確認
                        </div>
                        <div onClick={handleCancelRemarks} className="cursor-pointer text-[20px] font-[500] w-full flex items-center justify-center p-[12px] rounded-[99px] text-[black]">
                            取消
                        </div>
                    </div>
                </div>
            }
            {isloading&&<LoadingPage/>}
        </div>
    )
}


export default connect((store)=>{
    return{
      token:store.tokenReducer,
      control:store.controlReducer,
    }
  })(Page)




  const ReloadSvg = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={17}
      fill="none"
      {...props}
    >
      <path
        fill="#7A7A7A"
        d="M14.445 7.931a7.07 7.07 0 0 0-1.071-2.545 7.16 7.16 0 0 0-1.922-1.922 7.077 7.077 0 0 0-4-1.214V.667L4.31 3.039l3.144 2.373v-1.58a5.505 5.505 0 0 1 3.113.943 5.539 5.539 0 0 1 .82 8.507 5.557 5.557 0 0 1-2.797 1.508 5.641 5.641 0 0 1-2.231 0 5.538 5.538 0 0 1-4.42-5.424H.354c.001 1.418.425 2.804 1.218 3.98a7.192 7.192 0 0 0 1.92 1.92 7.078 7.078 0 0 0 3.98 1.217 7.128 7.128 0 0 0 5.902-3.139 7.08 7.08 0 0 0 1.215-3.978c0-.482-.049-.963-.145-1.435Z"
      />
    </svg>
  )