import { server_ip } from "../config";

export async function participateEvent(token,eventId,data=null){
    try {
        const response= await fetch(`${server_ip}/api/eventParticipations/`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                iswebregistration: true,
            },
            body: JSON.stringify({
                eventId,
                data,
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}
export async function merchantFetchParticipationWithUserData(token,filter,options,userFilter){
    try {
        const response= await fetch(`${server_ip}/api/eventParticipations/merchant/fetch`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                iswebregistration: true,
            },
            body: JSON.stringify({
                filter,
                options,
                userFilter
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}
export async function merchantChangeParticipationStatus(token,userId,eventId,status){
    try {
        const response= await fetch(`${server_ip}/api/eventParticipations/merchant/approve`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                iswebregistration: true,
            },
            body: JSON.stringify({
                userId,
                eventId,
                status
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}
export async function merchantAddRemarks(token,userId,eventId,remarks){
    try {
        const response= await fetch(`${server_ip}/api/eventParticipations/merchant/remarks`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId,
                eventId,
                remarks
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}

export async function checkIfEmailRegisteredEvent(eventId,email){
    try {
        const response= await fetch(`${server_ip}/api/eventParticipations/check/register`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                eventId
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}