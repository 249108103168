import {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {COOKIESPREFIX } from '../config';
import { useCookies } from 'react-cookie';
import { Login } from '../actions/actions';
import { connect } from 'react-redux';
import TgatherLogoSvg from '../svgs/TgatherLogoSvg';

function Screen(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [curScreen, setCurScreen] = useState('/')
    const [cookies, setCookie, removeCookie] = useCookies([`${COOKIESPREFIX}_cred`,`${COOKIESPREFIX}_token`]);
    useEffect(() => {
        const pathName = location.pathname.split('/')[1]
        setCurScreen(`/${pathName}`)
    },[location])
    const navigateTo=(str)=>()=>{
        navigate(str)
    }
    function logout(){
        removeCookie(`${COOKIESPREFIX}_cred`,{path:'/'});
        removeCookie(`${COOKIESPREFIX}_token`,{path:'/'});
        props.dispatch(Login(false))
        navigate('/')
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
        useEffect(()=>{
            function handleWindowResize() {setWindowSize(getWindowSize()); }
            window.addEventListener('resize', handleWindowResize);
            return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])


    const [isShowMoblieMenu,setisShowMoblieMenu]=useState(false)
    return (
        <div className='header relative text-white bg-[#dddddd] flex justify-start items-stretch flex-row w-full text-[12px]'>
            <div className='h-full relative text-white bg-[#dddddd] flex justify-between items-stretch flex-row w-full pr-[16px]' style={{overflowX:'auto',gap:24}}>

                <div className='absolute bottom-0 w-full h-[105px]' style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 69%, rgba(255, 255, 255, 0.6) 87.5%, #FFFFFF 100%)',zIndex:1}}/>

                <div className="flex items-center justify-center header-logo z-[1]">
                    <TgatherLogoSvg width={windowSize.innerWidth<1024?114:228}/>
                    <div className='d-hidden-m flex text-[10px] font-[400] text-[#6E604C] ml-[16px]'>
                        Connect Talents <br/> Cultivate Lifestyle Excellence
                    </div>
                </div>

                {/*mobile menu*/}
                <div className='d-hidden-m-flex flex items-center justiify-center'>
                    <div onClick={()=>{setisShowMoblieMenu(true)}} className='flex flex-col cursor-pointer z-[2]' style={{gap:4}}>
                        <div className='w-[20px] h-[3px] rounded-[2px] bg-[#6E604C]'/>
                        <div className='w-[20px] h-[3px] rounded-[2px] bg-[#6E604C]'/>
                        <div className='w-[20px] h-[3px] rounded-[2px] bg-[#6E604C]'/>
                    </div>
                </div>

                {/*desktop menu*/}
                <div className='d-flex-m-hidden flex-1 flex-row gap-[16px] justify-end text-[#717171] font-[500] text-[18px] z-[1]' >
                    <div onClick={navigateTo('/')} style={{color:['/','/home','/index.html'].includes(curScreen)?'#9B8C6F':'#717171'}} className="relative flex items-center justify-center hover:font-bold cursor-pointer w-[156px] text-center">
                        首頁
                        {['/','/home','/index.html'].includes(curScreen)&&<div className='h-[4px] rounded-[2px] w-full bg-[#9B8C6F] absolute bottom-0'/>}
                    </div>
                    <div onClick={navigateTo('/aboutus')} style={{color:['/aboutus'].includes(curScreen)?'#9B8C6F':'#717171'}} className="relative flex items-center justify-center hover:font-bold cursor-pointer  w-[156px] text-center">
                        關於T.Gather
                        {['/aboutus'].includes(curScreen)&&<div className='h-[4px] rounded-[2px] w-full bg-[#9B8C6F] absolute bottom-0'/>}
                    </div>
                    <div onClick={navigateTo('/event')} style={{color:['/event'].includes(curScreen)?'#9B8C6F':'#717171'}} className="relative flex items-center justify-center hover:font-bold cursor-pointer  w-[180px] text-center">
                        ⼈才⾼峰論壇2024
                        {['/event'].includes(curScreen)&&<div className='h-[4px] rounded-[2px] w-full bg-[#9B8C6F] absolute bottom-0'/>}
                    </div>
                    <div onClick={navigateTo('/register')} style={{color:['/register'].includes(curScreen)?'#9B8C6F':'#717171'}} className="relative flex items-center justify-center hover:font-bold cursor-pointer  w-[156px] text-center">
                        峰會登記
                        {['/register'].includes(curScreen)&&<div className='h-[4px] rounded-[2px] w-full bg-[#9B8C6F] absolute bottom-0'/>}
                    </div>
                </div>

                {/* <div className='flex flex-row gap-[16px] justify-center text-[#717171] font-[500] text-[20px] z-[1]' >
                    <div onClick={navigateTo('/')} className="relative flex items-center justify-center hover:font-bold cursor-pointer w-[156px] text-center">
                        推廣通知
                    </div>
                    <div onClick={navigateTo('/')} className="relative flex items-center justify-center hover:font-bold cursor-pointer w-[156px] text-center">
                        系統通知
                    </div>
                </div> */}


                {/* <div className="flex-1 flex items-center justify-end">
                    <div className='mr-[64px]'>{props.token?.isLogin&&props.control?.userData?.email}</div>
                    {props.token?.isLogin?
                    <div onClick={logout} className="flex items-center justify-center hover:font-bold cursor-pointer">
                        登出
                    </div>
                    :<div onClick={navigateTo('/login')} className="flex items-center justify-center hover:font-bold cursor-pointer">
                        登入/註冊
                    </div>}
                </div> */}
            </div>

            {isShowMoblieMenu&&<div className='fixed top-0 left-0 z-[100] bg-[white] py-[10%] px-[24px] flex flex-col items-center justify-start text-[#717171] font-[700] text-[24px] gap-[10%]'  style={{height:'100vh',width:'100vw'}}>
                <div onClick={()=>{setisShowMoblieMenu(false);navigateTo('/')()}} style={{color:['/','/home','/index.html'].includes(curScreen)?'#9B8C6F':'#717171'}} className="relative flex items-center justify-center hover:font-bold cursor-pointer w-[156px] text-center py-[8px] mt-[24px]">
                    首頁
                    {['/','/home','/index.html'].includes(curScreen)&&<div className='h-[4px] rounded-[2px] w-full bg-[#9B8C6F] absolute bottom-0'/>}
                </div>
                <div onClick={()=>{setisShowMoblieMenu(false);navigateTo('/aboutus')()}} style={{color:['/aboutus'].includes(curScreen)?'#9B8C6F':'#717171'}} className="relative flex items-center justify-center hover:font-bold cursor-pointer  w-[156px] text-center py-[8px]">
                    關於T.Gather
                    {['/aboutus'].includes(curScreen)&&<div className='h-[4px] rounded-[2px] w-full bg-[#9B8C6F] absolute bottom-0'/>}
                </div>
                <div onClick={()=>{setisShowMoblieMenu(false);navigateTo('/event')()}} style={{color:['/event'].includes(curScreen)?'#9B8C6F':'#717171'}} className="relative flex items-center justify-center hover:font-bold cursor-pointer  w-[180px] text-center py-[8px]">
                    ⼈才⾼峰論壇2024
                    {['/event'].includes(curScreen)&&<div className='h-[4px] rounded-[2px] w-full bg-[#9B8C6F] absolute bottom-0'/>}
                </div>
                <div onClick={()=>{setisShowMoblieMenu(false);navigateTo('/register')()}} style={{color:['/register'].includes(curScreen)?'#9B8C6F':'#717171'}} className="relative flex items-center justify-center hover:font-bold cursor-pointer  w-[156px] text-center py-[8px]">
                    峰會登記
                    {['/register'].includes(curScreen)&&<div className='h-[4px] rounded-[2px] w-full bg-[#9B8C6F] absolute bottom-0'/>}
                </div>

                <div className='absolute top-[20px] right-[20px] cursor-pointer' onClick={()=>{setisShowMoblieMenu(false)}}>X</div>
            </div>}
        </div>
    )
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
      control:store.controlReducer,
    }
  })(Screen)