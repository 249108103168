import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../svgs/TgatherLogoSvg';
import { useEffect, useState } from "react";

export default function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo=(str)=>()=>{
        navigate(str)
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
        useEffect(()=>{
            function handleWindowResize() {setWindowSize(getWindowSize()); }
            window.addEventListener('resize', handleWindowResize);
            return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])

    const [isShowComingSoon,setisShowComingSoon]=useState(false)
    const cancelShowComingSoon=()=>{setisShowComingSoon(false)}
    const handleFollowUs=()=>{
        setisShowComingSoon(true)
    }

    return(
        <div className="w-full h-full flex flex-col items-center justify-start bg-[#ddd] overflow-hidden">
            
            <div className="w-full h-full flex-col flex items-center" style={{overflow:'auto',boxSizing:"border-box"}}>
                <img src='/aboutus2.png' className="w-full d-flex-m-hidden" style={{}}/>
                <img src='/aboutus1.png' className="w-full d-hidden-m-flex" style={{}}/>

                <div className="flex-col flex w-full px-[16px] items-center">
                    <div className="text-[#6E604C] text-[20px] font-[700] d-text-center-m-text-left mt-[24px] w-full">加入 T.Gather 盡享生活精彩禮遇</div>
                    <div className="max-w-[1000px] w-full text-[#6E604C] text-[13px] font-[400] mt-[24px] d-text-center-m-text-left d-text-center-m-text-left " style={{boxSizing:"border-box"}}>
                        作為T.Gather特級會員，您可以享受豐富的獎勵計劃。每次消費都能獲得T.Dollar，這些積分可以兌換限量精品禮遇、獨家體驗活動、尊享會員優惠，以及升級服務體驗。我們深信，每一位會員都應該享有與眾不同的待遇。
                        <br/><br/>
                        我們為會員提供一系列專屬禮遇，包括24小時專屬禮賓服務、優先預訂權益、驚喜生日特權，以及會員專享活動。這些特別設計的服務旨在讓您的每一次體驗都充滿驚喜。
                    </div>
           
                    
                    <div onClick={handleFollowUs} className="w-[90%] max-w-[268px] min-h-[50px] cursor-pointer flex justify-center items-center text-white text-[16px] font-[700] rounded-[99px] mt-[64px] mb-[48px]" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                        加入T.Gather特級會員
                    </div>

                    <div className="relative flex flex-row flex-wrap w-full justify-between max-w-[1000px]">
                        <img src='/i1.png' className="d-w-49-m-100 mb-[12px]" style={{objectFit:'contain'}}/>
                        <img src='/i2.png' className="d-w-49-m-100 mb-[12px]" style={{objectFit:'contain'}}/>
                    </div>

                    <div className="text-[#6E604C] text-[24px] font-[700] text-center mt-[32px] w-full mb-[200px]">立即加入T.Gather特級會員，開啟您的品味生活之旅。收集積分，兌換獎賞，體驗精緻生活的每一刻。</div>


                    <div className="text-[#6E604C] text-[24px] font-[700] text-center mt-[32px] w-full mb-[200px]"></div>


                    <div className='max-w-[1000px] w-full flex flex-col items-start' style={{whiteSpace:'pre-wrap',fontSize:14}}>
                        <b className="text-[24px]">退款政策</b><br/>
                        我們 T.Gather 致力於為客戶提供無縫和愉快的體驗。請仔細閱讀我們的退款政策，以了解有關會籍和活動入場券退款的權利和責任。
                        <br/><br/>
                        會籍退款:<br/>
                        所有會籍購買均不設退款。一旦支付會籍費用，將不可轉讓或退款。<br/><br/>
                        活動入場券退款:<br/>
                        活動入場券通常不設退款，除非該活動被 T.Gather 取消。如活動取消，持票人將收到通知，並且將按照有關安排辦理退款。<br/><br/>
                        退款程序:<br/>
                        若要為取消的活動申請退款，請在活動取消後 30 天內聯繫我們的客戶支援團隊 support@tgather.net / 5392 2633。<br/>
                        退款將在獲得批准後的 30 個工作天內處理。<br/><br/>
                        例外情況:<br/>
                        對於特殊情況，退款事宜將視具體情況個案處理。如有任何疑問，請隨時與我們聯繫。<br/><br/>
                        聯絡我們:<br/>
                        如有任何問題或需要進一步協助有關退款事宜，請隨時與我們聯繫 support@tgather.net / 5392 2633。<br/>
                        請注意，透過我們的網站購買即表示您同意遵守我們的退款政策。<br/>
                        <br/>
                        感謝您選擇 T.Gather。我們感謝您的理解和支持。<br/>
                    </div>

                    <div className="text-[#6E604C] text-[24px] font-[700] text-center mt-[32px] w-full mb-[200px]"></div>

                </div>

                {/*footer */}
                <div className="flex flex-row w-full justify-between items-center px-[24px] py-[16px] footer-font-1 text-[#6E604C] font-[700] bg-[#ddd] gap-[24px]">
                    港才薈 TGather
                    <div className="footer-font-2">
                        © 2024 Tgather Limited. All right reserved.
                    </div>
                </div>


            </div>

            {isShowComingSoon&&<div className="absolute top-0 bottom-0 left-0 right-0 bg-[#00000090] max-h-[100%] z-[100] flex justify-center items-center">
                <div className="absolute top-0 bottom-0 left-0 right-0 z-[101]"/>
                <div className="max-w-[800px] max-h-[90%] w-[90%] p-[24px] bg-[#B9B7AE] rounded-[24px] flex flex-col jusitfy-start items-center z-[102]" style={{overflowY:'auto'}}>
                    <img src='/comingsoon.png' className="flex-1 w-full max-w-[320px]" style={{}}/>

                    <div onClick={cancelShowComingSoon} className="cursor-pointer bg-[#0F737E] mt-[24px] text-[20px] font-[500] w-full flex items-center justify-center p-[12px] rounded-[99px] text-[white]">
                        確認
                    </div>
                </div>
            </div>
            }
         
            
        </div>
    )
}