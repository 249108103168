import './PageSelector.css'

export default function Component({page,total,totalPage,handleSelectPage,hideTotal}){
    return(

        <div className='flex text-[black] flex-row w-full justify-between items-center f-16-bold mb-6' style={{ gap: 16 }}>
            <div className='flex-row items-center w-[150px] text-black text-xs pl-[4px] font-bold'>
            
            </div>
            {/* Total {total} items&nbsp; */}
                       

            <div className='flex flex-row items-center text-black' style={{ gap: 24 }}>
                <div className='flex flex-row items-center'>
                    <div onClick={handleSelectPage(page - 1)} className='mousehover mr-[24px]'>
                        <SmallPointLeftSvg style={{cursor:'pointer'}}/>
                    </div>
                    {page === 1 || <div onClick={ handleSelectPage(1)} className={'page_unselected cursor-pointer select-none'}>
                        1
                    </div>}
                    {page > 5
                        ? <div className='page_unselected cursor-pointer select-none'>
                            ...
                        </div>
                        : totalPage > 1 && page > 2
                            ? <div onClick={handleSelectPage(2)} className={`page_unselected cursor-pointer select-none`}>
                                2
                            </div>
                            : <div />
                    }
                    {page > 4 && <div onClick={handleSelectPage(page - 2)} className='page_unselected cursor-pointer select-none'>
                        {page - 2}
                    </div>}
                    {page > 3 && <div onClick={handleSelectPage(page - 1)} className='page_unselected cursor-pointer select-none'>
                        {page - 1}
                    </div>}
                    <div onClick={handleSelectPage(page)} className='underline font-bold text-[#5FC5FF] cursor-pointer select-none mr-[24px]'>
                        {page}
                    </div>
                    {totalPage - 1 > page && <div onClick={handleSelectPage(page + 1)} className='page_unselected cursor-pointer select-none'>
                        {page + 1}
                    </div>}
                    {totalPage - 2 > page && <div onClick={handleSelectPage(page + 2)} className='page_unselected cursor-pointer select-none'>
                        {page + 2}
                    </div>}
                    {totalPage - 4 > page
                        ? <div className='page_unselected cursor-pointer select-none'>
                            ...
                        </div>
                        : totalPage - 3 > page
                            ? <div onClick={handleSelectPage(page + 3)} className='cursor-pointer select-none page_unselected'>
                                {page + 3}
                            </div>
                            : <div />
                    }
                    {page !== totalPage && <div onClick={handleSelectPage(totalPage)} className='page_unselected cursor-pointer select-none'>
                        {totalPage}
                    </div>}
                    <div onClick={handleSelectPage(page + 1)} className='cursor-pointer select-none'>
                        <SmallPointLeftSvg style={{transform: 'rotate(-180deg)', cursor:'pointer'}}/>
                    </div>
                </div>
            </div>

            <div className='flex-row items-center w-[150px] text-black text-xs pl-[4px] font-bold'>
                {hideTotal?'':`Total: ${total||0} item(s)`}
            </div>
        </div>
    )
}

const SmallPointLeftSvg = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={7}
      height={10}
      fill="none"
      {...props}
    >
      <path
        fill="#000"
        d="M6.341 8.825 2.526 5l3.817-3.825L5.167 0l-5 5 5 5 1.175-1.175Z"
      />
    </svg>
  )