import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useCookies } from "react-cookie";
import { COOKIESPREFIX } from "../config";
import { validateEmail } from "../utils/util";
import { merchantLoginWithPassword,searchUsers } from "../services/users";
import { Login } from "../actions/actions";
import PageSelector from '../components/PageSelector';
import LoadingPage from "../components/Loading";
import DropdownMenu from '../components/DropdownMenu'

const limit = 10
function Page(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo=(str)=>()=>{
        navigate(str)
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
        useEffect(()=>{
            function handleWindowResize() {setWindowSize(getWindowSize()); }
            window.addEventListener('resize', handleWindowResize);
            return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])

    const [cookies, setCookie, removeCookie] = useCookies([`${COOKIESPREFIX}_cred`,`${COOKIESPREFIX}_token`]);
    const [email,setEmail]=useState('');
    const [isloading,setisloading]=useState(false);
    const handleEmailInput=(e)=>{setEmail(e.target.value)};
    const [password,setPassword]=useState('');
    const handlePasswordInput=(e)=>{setPassword(e.target.value)};

    const handleLogin=async ()=>{
        if(!validateEmail(email)){
            alert('Invalid Email');return
        }
        if(!password){
            alert('Invalid');return
        }
        try{
            let res =  await merchantLoginWithPassword(email,password)
            if(res.errno){
                alert(res.message||'Invalid');
                return
            }
            
            let token = res?.token?.token
            let userId = res?.token?.userId
            props.dispatch(Login(true,token,'merchant',email,userId))
     
            setCookie(`${COOKIESPREFIX}_cred`, {email:email,usertype: 'merchant'}, { path: '/' });
            setCookie(`${COOKIESPREFIX}_token`, token, { path: '/', expires: new Date(Date.now()+1000*60*60*24*7)}); //7 days

        }catch(e){
            alert(e.message)
        }finally{
            setisloading(false)
        }
    }
    useEffect(()=>{
        if(props?.token?.usertype&&props?.token?.usertype!=='merchant'){
            props.dispatch(Login(false))
        }
    },[props.token])






    const [searchParams, setSearchParams] = useSearchParams();
    const [totalItem, setTotalItem] = useState(1)
    const totalPage = Math.ceil(totalItem/limit)||1
    const [data,setData]=useState([])
    const page =  parseInt(searchParams.get('page'))||1
    const filterLastname = searchParams.get('lastname')
    const filterFirstname = searchParams.get('firstname')
    const filterEmail = searchParams.get('email')
    const filterMobile = searchParams.get('mobile')
    const filterType = searchParams.get('type')||'全部'
    //
    const handleSearchLastname=(e)=>{setSearchParams({page:1,lastname:e.target.value})}
    const handleSearchFirstname=(e)=>{setSearchParams({page:1,firstname:e.target.value})}
    const handleSearchEmail=(e)=>{setSearchParams({page:1,email:e.target.value})}
    const handleSearchMobile=(e)=>{setSearchParams({page:1,mobile:e.target.value})}
    useEffect(() => {
        if(!props.token.isLogin)return
        const timer = setTimeout(fetchData, 500)
        const interval = setInterval(fetchData,1000*30)
        return () => {
            clearTimeout(timer)
            clearInterval(interval)
        }
    },[searchParams,props.token])
    async function fetchData(){
        // formatDataForChart()
        const filter = {type:'user',status:'active'}
 
        if(filterLastname) filter['data.lastname'] = {"$regex": filterLastname, "$options":'i'}
        if(filterFirstname) filter['data.firstname'] = {"$regex": filterFirstname, "$options":'i'}
        if(filterEmail) filter['email'] = {"$regex": filterEmail, "$options":'i'}
        if(filterMobile) filter['mobile'] = {"$regex": filterMobile, "$options":'i'}
        const res = await searchUsers(props.token.token, filter, {limit,skip:limit*(page-1),sort:{_id:-1}})
        console.log(res)
        if(res.errno) return
        setTotalItem(res.count)
        setData(res.result)

        const toPage = Math.ceil(res.count/limit) || 1
        if(page > toPage) handleParamChange('page', 1)
    }
    const handleSelectPage=(p)=>()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(val !== '') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function selectFilter(item,index){
        searchParams.delete('type')
        searchParams.set('type', item)
        setSearchParams(searchParams)
    }


   
    if(!props.token?.isLogin){
        return(
            <div className="w-full h-full flex flex-col items-center justify-center bg-[#ddd] overflow-hidden">
                <div className="text-[#6E604C]">第四屆香港國際⼈才⾼峰論壇 - 登記核准</div>
                <div className="relative max-w-[400px] w-full border-white border-2 d-pad-24-m-16 rounded-[24px] flex flex-col justify-center items-center mt-[24px] overflow-hidden">
                    <div className='absolute bottom-0 w-full h-full' style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 69%, rgba(255, 255, 255, 0.6) 87.5%, #FFFFFF 100%)',zIndex:1,}}/>
                    <div className="text-[#6E604C] font-[700] text-[24px]">登入</div>
                    <div className="font-bold mt-[24px] w-full text-left text-[#6E604C] z-[2">電郵地址</div>
                    <div className='w-full mt-[4px] z-[2]' style={{borderBottom:'1px solid black',padding:'8px 12px'}}>
                        <input value={email} onChange={handleEmailInput} name='email' className='w-full' style={{border:'none',background:"transparent"}}  placeholder='請輸入電郵地址'/> 
                    </div>
                    <div className="font-bold mt-[24px] w-full text-left text-[#6E604C] z-[2">密碼</div>
                    <div className='w-full mt-[4px] z-[2]' style={{borderBottom:'1px solid black',padding:'8px 12px'}}>
                        <input value={password} onChange={handlePasswordInput} type='password' name='password' className='w-full' style={{border:'none',background:"transparent"}}  placeholder='請填寫密碼'/> 
                    </div>
                    <div onClick={handleLogin} className="w-[100%] min-h-[50px] cursor-pointer z-[2] flex justify-center items-center text-white text-[16px] font-[700] rounded-[99px] mt-[64px] mb-[24px]" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                        登入
                    </div>
                </div>        
            </div>
        )
    }


    return(
        <div className="w-full h-full flex flex-col items-center justify-start bg-[#ddd] overflow-hidden p-[16px]">
            
            <div className="text-[#6E604C]">第四屆香港國際⼈才⾼峰論壇 - 登記核准</div>
            <div className="w-full flex flex-row gap-[16px] mt-[24px]">
                <input onChange={handleSearchLastname} placeholder="按姓氏搜尋" className="p-[4px] rounded-[4px]"/>
                <input onChange={handleSearchFirstname} placeholder="按名字搜尋" className="p-[4px] rounded-[4px]"/>
                <input onChange={handleSearchEmail} placeholder="透過電子郵件搜尋" className="p-[4px] rounded-[4px]"/>
                <input onChange={handleSearchMobile} placeholder="透過電話搜尋" className="p-[4px] rounded-[4px]"/>
                <div className="flex flex-row flex-1 justify-end">
                    <DropdownMenu value={filterType} select={selectFilter} options={['全部','待批准','已核准','已拒絕']}/>
                </div>
            </div>
            <div className="flex-1 w-full flex flex-col py-[16px]" style={{overflowY:'auto'}}>
                {/*Header */}
                <div className="flex flex-row justify-betwen items-center w-full text-[12px] text-[#6E604C] font-[700] pb-[8px]" style={{borderBottom:'2px solid #6E604C'}}>
                    <div className="flex-[2]">電郵地址(Email)</div>
                    <div className="flex-[2]">電話(Mobile)</div>
                    <div className="flex-1">性別(Gender)</div>
                    <div className="flex-1">姓(Surname)</div>
                    <div className="flex-1">名(Firstname)</div>
                    <div className="flex-[2]">公司/機構名稱(Company)</div>
                    <div className="flex-[2]">職務(Title)</div>
                </div>

                <div className="flex-1 w-full flex flex-col mt-[16px]">
                    {data?.map((item,index)=>
                        <div key={item?._id} className="flex flex-row justify-betwen items-center w-full text-[12px] text-[black] font-[400] py-[8px]" style={{borderBottom:'.5px solid #fff'}}>
                            <div className="flex-[2]">{item?.email||''}</div>
                            <div className="flex-[2]">{item?.mobile||''}</div>
                            <div className="flex-1">{item?.data?.gender||''}</div>
                            <div className="flex-1">{item?.data?.lastname||''}</div>
                            <div className="flex-1">{item?.data?.firstname||''}</div>
                            <div className="flex-[2]">{item?.data?.company||''}</div>
                            <div className="flex-[2]">{item?.data?.position||''}</div>                
                        </div>
                    )}
                </div>
            </div>

         
            <PageSelector page={page} total={totalItem} totalPage={totalPage} handleSelectPage={handleSelectPage}/>
        
            {isloading&&<LoadingPage/>}
        </div>
    )
}


export default connect((store)=>{
    return{
      token:store.tokenReducer,
      control:store.controlReducer,
    }
  })(Page)




  const ReloadSvg = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={17}
      fill="none"
      {...props}
    >
      <path
        fill="#7A7A7A"
        d="M14.445 7.931a7.07 7.07 0 0 0-1.071-2.545 7.16 7.16 0 0 0-1.922-1.922 7.077 7.077 0 0 0-4-1.214V.667L4.31 3.039l3.144 2.373v-1.58a5.505 5.505 0 0 1 3.113.943 5.539 5.539 0 0 1 .82 8.507 5.557 5.557 0 0 1-2.797 1.508 5.641 5.641 0 0 1-2.231 0 5.538 5.538 0 0 1-4.42-5.424H.354c.001 1.418.425 2.804 1.218 3.98a7.192 7.192 0 0 0 1.92 1.92 7.078 7.078 0 0 0 3.98 1.217 7.128 7.128 0 0 0 5.902-3.139 7.08 7.08 0 0 0 1.215-3.978c0-.482-.049-.963-.145-1.435Z"
      />
    </svg>
  )