import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../svgs/TgatherLogoSvg';
import { useEffect, useState } from "react";
import { validateEmail } from "../utils/util";
import {sendContactUsMessage} from '../services/contactus';

export default function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo=(str)=>()=>{
        navigate(str)
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
        useEffect(()=>{
            function handleWindowResize() {setWindowSize(getWindowSize()); }
            window.addEventListener('resize', handleWindowResize);
            return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])

    const [email,setEmail]=useState('')
    const handleFollowUs=async ()=>{
        if(!validateEmail(email)){
            alert('無效的電子郵件');
            return
        }
        let data={
            'name':"no-name",
            email,
            'message':"web-email-subscription"
        }
        let result = await sendContactUsMessage(data)
        if(result.error){
            alert(result?.message||'error')
        }else{
            alert('我們已登記您的電子郵件。我們將隨時向您通報我們的最新消息')
            setEmail('')
        }
    }

    return(
        <div className="w-full h-full flex flex-col items-center justify-start bg-[#ddd] overflow-hidden">
            
            <div className="w-full h-full flex-col flex items-center" style={{overflow:'auto',boxSizing:"border-box"}}>
                <img src='/home2.png' className="w-full d-flex-m-hidden" style={{}}/>
                <img src='/home1.png' className="w-full d-hidden-m-flex" style={{}}/>

                <div className="flex-col flex w-full px-[16px] items-center">
                    <div className="text-[#6E604C] text-[20px] font-[700] d-text-center-m-text-left mt-[24px] w-full">加入 T.Gather 盡享生活精彩禮遇</div>
                    <div className="max-w-[1000px] w-full text-[#6E604C] text-[13px] font-[400] mt-[24px] d-text-center-m-text-left d-text-center-m-text-left " style={{boxSizing:"border-box"}}>
                        作為T.Gather特級會員，您可以享受豐富的獎勵計劃。每次消費都能獲得T.Dollar，這些積分可以兌換限量精品禮遇、獨家體驗活動、尊享會員優惠，以及升級服務體驗。我們深信，每一位會員都應該享有與眾不同的待遇。
                        <br/><br/>
                        我們為會員提供一系列專屬禮遇，包括24小時專屬禮賓服務、優先預訂權益、驚喜生日特權，以及會員專享活動。這些特別設計的服務旨在讓您的每一次體驗都充滿驚喜。
                    </div>
                    <div className="text-[#6E604C] text-[20px] font-[700] d-text-center-m-text-left mt-[32px] w-full">填寫電郵地址, 獲得 T.Gather 最新消息</div>

                    <div className="max-w-[1000px] w-full h-[88px] min-h-[88px] flex flex-row justify-center items-center gap-[8px] bg-[#fff] mt-[24px] rounded-[99px] px-[24px]">
                        <input value={email} onChange={(e)=>{setEmail(e.target.value)}} className="emailinput flex flex-1 bg-[transparent] text-[700] text-[16px] p-[4px]" style={{}} placeholder="填寫電郵地址"/>

                        <div onClick={handleFollowUs} className="w-[200px] min-h-[50px] cursor-pointer flex justify-center items-center text-white text-[16px] font-[700] rounded-[99px] d-flex-m-hidden" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                            追蹤T.Gather
                        </div>
                    </div>

                    <div onClick={handleFollowUs} className="w-[90%] max-w-[268px] min-h-[50px] cursor-pointer flex justify-center items-center text-white text-[16px] font-[700] rounded-[99px] d-hidden-m-flex mt-[24px]" style={{background:'linear-gradient(180deg, #8C866B 0%, rgba(143, 137, 109, 0.96) 14%, rgba(153, 146, 116, 0.84) 32%, rgba(169, 160, 127, 0.8) 53%, rgba(153, 146, 116, 0.4) 100%)',border: '1px solid #8F896DF5'}}>
                            追蹤T.Gather
                    </div>

                </div>
                <img src='/aboutus2.png' className="w-full d-flex-m-hidden mt-[64px]" style={{}}/>
                <img src='/aboutus1.png' className="w-full d-hidden-m-flex mt-[64px]" style={{}}/>

                {/*footer */}
                <div className="flex flex-row w-full justify-between items-center px-[24px] py-[16px] footer-font-1 text-[#6E604C] font-[700] bg-[#ddd] gap-[24px]">
                    港才薈 TGather
                    <div className="footer-font-2">
                        © 2024 Tgather Limited. All right reserved.
                    </div>
                </div>


            </div>
         
            
        </div>
    )
}