import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo=(str)=>()=>{
        navigate(str)
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
        useEffect(()=>{
            function handleWindowResize() {setWindowSize(getWindowSize()); }
            window.addEventListener('resize', handleWindowResize);
            return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])


    return(
        <div className="w-full h-full flex flex-col items-center justify-start bg-[#ddd] overflow-hidden">
            
            <div className="w-full h-full flex-col flex items-center jusify-between" style={{overflow:'auto',boxSizing:"border-box"}}>
                <img src='/home2.png' className="w-full d-flex-m-hidden" style={{}}/>
                <img src='/home1.png' className="w-full d-hidden-m-flex" style={{}}/>

                <div className="flex-col flex w-full px-[16px] items-center py-[24px] min-h-[50vh]">
                    <div className="text-[#6E604C] text-[20px] font-[700] d-text-center-m-text-left mt-[24px] w-full">T.Gather 刪除帳戶步驟</div>
                    <div className="max-w-[1000px] w-full text-[#6E604C] text-[13px] font-[400] mt-[24px] text-left " style={{boxSizing:"border-box"}}>
                    <div className="max-w-[1000px]">
                        應用程式: T.Gather<br/>
                        應用程式平台: Google Play<br/>
                        開發人員: Aquila Technology <br/>
                        刪除帳戶步驟:<br/>
                        1. 使用T.Gather應用程式登入你的帳號<br/>
                        2. 在右下角選單中選擇"個人"<br/>
                        3. 在個人頁面的右上角按 "會員檔案" 標誌<br/>
                        4. 在會員檔案頁面的最底下按 "刪除T.gather帳戶" 按鍵<br/>
                        帳戶刪除後，我們不會保留任何資料<br/>
                        </div>
                    </div>
                    
                </div>


                {/*footer */}
                <div className="flex flex-row w-full justify-between items-center px-[24px] py-[16px] footer-font-1 text-[#6E604C] font-[700] bg-[#ddd] gap-[24px]">
                    港才薈 TGather
                    <div className="footer-font-2">
                        © 2024 Tgather Limited. All right reserved.
                    </div>
                </div>


            </div>
         
            
        </div>
    )
}