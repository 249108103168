import { server_ip } from "../config";

export async function sendContactUsMessage(data){
    try {
        const response= await fetch(`${server_ip}/api/contactUsMessages`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}
