import { useLocation, useNavigate } from "react-router-dom";

export default function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo=(str)=>()=>{
        navigate(str)
    }


    return(
        <div className="w-full h-full flex flex-col items-center justify-start bg-[#ddd] overflow-hidden">
            
            <div className="w-full h-full flex-col flex items-center p-[16px] py-[48px]" style={{overflow:'auto',boxSizing:"border-box",whiteSpace:"pre-line"}}>

                <div className="flex-col flex w-full px-[16px] items-center">
                    {`Privacy Policy
This privacy policy applies to the T.Gather app (hereby referred to as "Application") for mobile devices that was created by T.Gather (hereby referred to as "Service Provider") as a Commercial service. This service is intended for use "AS IS".
Information Collection and Use
The Application collects information when you download and use it. This information may include information such as
Your device's Internet Protocol address (e.g. IP address)
The pages of the Application that you visit, the time and date of your visit, the time spent on those pages
The time spent on the Application
The operating system you use on your mobile device

The Application does not gather precise information about the location of your mobile device.

The Service Provider may use the information you provided to contact you from time to time to provide you with important information, required notices and marketing promotions.

For a better experience, while using the Application, the Service Provider may require you to provide us with certain personally identifiable information, including but not limited to Email, phone number, age, first name, last name. The information that the Service Provider request will be retained by them and used as described in this privacy policy.
Third Party Access
Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Application and their service. The Service Provider may share your information with third parties in the ways that are described in this privacy statement.

Please note that the Application utilizes third-party services that have their own Privacy Policy about handling data. Below are the links to the Privacy Policy of the third-party service providers used by the Application:
Google Play Services
Expo

The Service Provider may disclose User Provided and Automatically Collected Information:
as required by law, such as to comply with a subpoena, or similar legal process;
when they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;
with their trusted services providers who work on their behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.

Opt-Out Rights
You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
Data Retention Policy
The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please contact them at info@aquila-tech.io and they will respond in a reasonable time.
Children
The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.

The Application does not address anyone under the age of 13. The Service Provider does not knowingly collect personally identifiable information from children under 13 years of age. In the case the Service Provider discover that a child under 13 has provided personal information, the Service Provider will immediately delete this from their servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact the Service Provider (info@aquila-tech.io) so that they will be able to take the necessary actions.
Security
The Service Provider is concerned about safeguarding the confidentiality of your information. The Service Provider provides physical, electronic, and procedural safeguards to protect information the Service Provider processes and maintains.
Changes
This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.

This privacy policy is effective as of 2024-11-20
Your Consent
By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
Contact Us
If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at info@aquila-tech.io.

私隱政策

本私隱政策適用於由T.Gather（以下簡稱「服務提供者」）開發的T.Gather應用程式（以下簡稱「應用程式」），作為一項商業服務的流動裝置應用程式。本服務按「現況」提供。

資料收集與使用
當您下載並使用本應用程式時，系統會收集資料。這些資料可能包括：
您裝置的網際網路通訊協定位址（即IP位址）
您瀏覽應用程式的頁面、瀏覽時間和日期、在這些頁面的停留時間
在應用程式的使用時間您流動裝置使用的作業系統, 本應用程式不會收集您流動裝置的精確位置資料。
服務提供者可能會使用您提供的資料，不時與您聯繫，為您提供重要資訊、必要通知和營銷推廣。
為提供更好的服務體驗，服務提供者在您使用應用程式時，可能會要求您提供某些可識別個人身份的資料，包括但不限於電郵地址、電話號碼、年齡、名字、姓氏。服務提供者所要求的資料將由其保留，並按本私隱政策所述方式使用。
第三方存取
僅經匿名化處理的綜合數據會定期傳送至外部服務，以協助服務提供者改進應用程式及其服務。服務提供者可能會按本私隱聲明所述方式與第三方共享您的資料。
請注意，本應用程式使用的第三方服務均有其自身關於數據處理的私隱政策。以下是本應用程式使用的第三方服務提供者的私隱政策連結：
Google Play Services
Expo
服務提供者可能會在以下情況下披露用戶提供及自動收集的資料：
因應法律要求，如遵從傳票或類似的法律程序；
當他們真誠地相信披露資料對保護其權利、保護您或他人的安全、調查欺詐行為或回應政府要求是必要的；
與代表他們工作的可信賴服務提供者共享，這些提供者不會獨立使用我們向其披露的資料，並已同意遵守本私隱聲明中規定的規則。

退出權利
您可以通過卸載應用程式輕鬆停止所有資料收集。您可以使用流動裝置或應用程式市場或網絡提供的標準卸載程序。

數據保留政策
服務提供者將在您使用應用程式期間及其後的合理時間內保留用戶提供的數據。如果您希望刪除您通過應用程式提供的用戶數據，請聯繫info@aquila-tech.io，他們將在合理時間內回覆。

兒童
服務提供者不會使用本應用程式故意向13歲以下兒童徵集數據或進行營銷。
本應用程式不針對13歲以下人士。服務提供者不會故意收集13歲以下兒童的可識別個人資料。若服務提供者發現有13歲以下兒童提供個人資料，將立即從伺服器中刪除。如果您是家長或監護人，並且知道您的子女向我們提供了個人資料，請聯繫服務提供者（info@aquila-tech.io），以便他們採取必要行動。

安全性
服務提供者致力保障您資料的機密性。服務提供者提供實體、電子和程序性保障措施，以保護其處理和維護的資料。

變更
本私隱政策可能會因任何原因不時更新。服務提供者將通過在本頁更新新的私隱政策來通知您任何變更。建議您定期查閱本私隱政策以了解任何變更，因持續使用將被視為同意所有變更。

本私隱政策自2024年11月20日起生效

您的同意
使用本應用程式即表示您同意按照本私隱政策（現時及經我們修訂後的版本）處理您的資料。

聯絡我們
如果您在使用應用程式時對私隱有任何疑問，或對相關做法有任何問題，請通過電郵info@aquila-tech.io聯絡服務提供者。`}
                </div>

                {/*footer */}
                <div className="flex flex-row w-full justify-between items-center px-[24px] py-[16px] footer-font-1 text-[#6E604C] font-[700] bg-[#ddd] gap-[24px]">
                        港才薈 TGather
                    <div className="footer-font-2">
                        © 2024 Tgather Limited. All right reserved.
                    </div>
                </div>


            </div>
         
            
        </div>
    )
}