import { server_ip } from "../config";

export async function requestOtpWithEmail(email){
    try {
        const response= await fetch(`${server_ip}/api/users/email/requestOtp`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email
            })
        })

        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}

export async function loginWithEmailOtp (email,otp){
    try {
        const response= await fetch(`${server_ip}/api/users/email/loginWithOtp`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                otp
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}
export async function getProfile(token){
    try {
        const response= await fetch(`${server_ip}/api/users/profile`,{
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization : `Bearer ${token}`
            },
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}
export async function validToken(token){
    try {
        const response= await fetch(`${server_ip}/api/valid`,{
            cache: 'no-store',
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}


export async function uploadNewUserProfile(token,data){
    try {
        let response= await fetch(`${server_ip}/api/users/profile`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        let json = await response.json();
        return json
    }  catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}
export async function merchantLoginWithPassword(username,password){
    try {
        const response= await fetch(`${server_ip}/api/users/merchant/login`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}

export async function searchUsers(token, filter ,options){
    try {
        const response= await fetch(`${server_ip}/api/users/merchant/profile/search`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                filter,
                options,
            })
        })
        return await response.json();
    } catch (error) {
        return {errno:1,message:'Unexpected error'}
    }
}